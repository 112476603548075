import Button from "components/button";
import Input from "components/input";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { plus, search } from "resources/images/images";
import styles from "./style.module.css";
import { useAuthContext } from "hooks/useAuthContext";
import { getAllProductsApi } from "networking/apis/products/getallproducts";
import { addProductToCartApi } from "networking/apis/cart/addproducttocart";
import { useCartDataContext } from "hooks/useCartDataContext";
import { useToast } from "providers/ToastProvider";
import { deleteProductApi } from "networking/apis/products/deleteproduct";
import { RiDeleteBin7Fill } from "react-icons/ri";
import { useSpinner } from "providers/SpinnerProvider";
import { strings } from "resources/strings/english-string";
import { useNetWorkContext } from "providers/NetWorkProvider";

const Products = () => {
    /*contexts*/
    const { storeData } = useAuthContext();
    const { handleGetCartProducts, update, setUpdate } = useCartDataContext();
    const { setIsLoading } = useSpinner();
    const { showToast } = useToast();
    const { isOnLine } = useNetWorkContext();

    /*strings*/
    const { productStrings } = strings;

    /*routing*/
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const navigation = useNavigate();

    /*states*/
    const [searchText, setSearchText] = useState("");
    const [products, setProducts] = useState();
    const [filteredProducts, setFilteredProducts] = useState(products);

    /***useEffect***/

    /*filtering of products*/

    useEffect(() => {
        setFilteredProducts(products);
    }, [products]);

    /*get all product onload the page*/

    useEffect(() => {
        if (storeData) {
            handleGetAllProducts();
        }
    }, [storeData, update]);

    /*search the products based on their titles*/

    const filterProduct = (e) => {
        setSearchText(e.target.value);
        setFilteredProducts(
            products.filter((product) =>
                JSON.stringify(product)
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
    };

    /*handle get all products*/

    const handleGetAllProducts = async () => {
        setIsLoading(true);
        try {
            const getAllProductsResponse = await getAllProductsApi();
            if (getAllProductsResponse.data.type === "success") {
                // console.log("getAllProductsResponse", getAllProductsResponse);
                setIsLoading(false);
                setProducts(getAllProductsResponse.data.data);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error inn fetching products", error);
        }
    };

    /*handle delete a product*/

    const handleDeleteProduct = async (product) => {
        try {
            setIsLoading(true);
            console.log("productId", product._id);
            const getDeleteProductResponse = await deleteProductApi(
                product._id
            );
            if (getDeleteProductResponse.data.type === "success") {
                handleGetCartProducts();
                setIsLoading(false);
                handleGetAllProducts();
                showToast.success(getDeleteProductResponse.data.message);
                setUpdate(!update);
            } else {
                setIsLoading(false);
                showToast.error(getDeleteProductResponse.data.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };

    /*handle add product to the cart*/

    const handleAddProductToCart = async (product) => {
        setIsLoading(true);
        try {
            let addProductToCartData = {
                store_id: product.store_id,
                product: product._id,
            };
            const addProductToCartResponse = await addProductToCartApi(
                addProductToCartData
            );
            if (addProductToCartResponse.data.type === "success") {
                setIsLoading(false);
                handleGetCartProducts();
                showToast.success(addProductToCartResponse.data.message);
            } else {
                setIsLoading(false);
                showToast.error(addProductToCartResponse.data.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error in adding product to cart", error);
        }
    };

    const renderTopSection = () => {
        return (
            <div className={styles.topSectionStyle}>
                <div className={styles.inputSectionStyle}>
                    <Input
                        placeholder="Search here"
                        value={searchText}
                        onChange={(e) => filterProduct(e)}
                        customInputStyle={styles.inputStyle}
                        customInputViewStyle={styles.customInputViewStyle}
                        customRightIconViewStyle={
                            styles.customRightIconViewStyle
                        }
                        rightIcon={search}
                    />
                </div>
                <Button
                    title="Add Product"
                    icon={plus}
                    onClick={() => navigation("/addProduct")}
                    btnStyle={styles.btnViewStyle}
                />
            </div>
        );
    };

    const renderTableTopSection = () => {
        return (
            <table>
                <thead>
                    <tr className={styles.headingStyle}>
                        <td className={styles.headingStyle3}>
                            {productStrings.sellerId}
                        </td>
                        <td className={styles.headingStyle3}>
                            {productStrings.brand}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.category}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.model}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.material}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.color}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.size}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.price}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.status}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.createdDate}
                        </td>
                        <td className={styles.headingStyle2}></td>
                        <td className={styles.headingStyle2}></td>
                        <td className={styles.headingStyle2}></td>
                    </tr>
                </thead>
            </table>
        );
    };

    const renderTableBottomSection = () => {
        return (
            <table>
                <tbody>
                    {filteredProducts &&
                        filteredProducts.map((item, index) => (
                            <tr
                                key={index}
                                className={styles.assetListDataRowStyle}>
                                <td className={styles.serialNoStyle}>
                                    <p
                                        className={[
                                            styles.serialNoTextStyle,
                                            filteredProducts.length === 1 &&
                                                styles.serialNoTextStyle2,
                                        ].join(" ")}>
                                        {item.product_uid}
                                    </p>
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.brand ? item.brand : "N/A"}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.category ? item.category : "N/A"}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.model ? item.model : "N/A"}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.material ? item.material : "N/A"}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.colour ? item.colour : "N/A"}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.size ? item.size : "N/A"}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.price ? item.price : "N/A"}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    <p
                                        className={
                                            item.availability_status ===
                                            "InStore"
                                                ? styles.statusInStoreViewStyle
                                                : item.availability_status ===
                                                  "Sold"
                                                ? styles.statusSoldViewStyle
                                                : item.availability_status ===
                                                  "Paid Out"
                                                ? styles.statusPaidOutViewStyle
                                                : item.availability_status ===
                                                  "Packed"
                                                ? styles.statusPackedViewStyle
                                                : ""
                                        }>
                                        {item.availability_status}
                                    </p>
                                </td>
                                <td className={styles.createdAtTextStyle}>
                                    {new Date(
                                        item.created_at
                                    ).toLocaleDateString("pt-PT")}
                                </td>
                                <td
                                    className={[
                                        styles.editBtnViewStyle,
                                        item.status === "Sold" ||
                                            "Paid Out" ||
                                            ("Packed" &&
                                                styles.disableBtnViewStyle),
                                    ].join(" ")}>
                                    <div
                                        onClick={() =>
                                            navigate("/addProduct", {
                                                state: {
                                                    data: item,
                                                    type: "edit",
                                                },
                                            })
                                        }>
                                        <p>{productStrings.edit}</p>
                                    </div>
                                </td>
                                {/* <td
                                    className={[
                                        styles.addBtnViewStyle,
                                        !item.availability_status &&
                                            styles.disableBtnViewStyle,
                                    ].join(" ")}
                                >
                                    <div
                                        onClick={
                                            item.availability_status
                                                ? () => {
                                                      handleAddProductToCart(
                                                          item
                                                      );
                                                  }
                                                : () => {}
                                        }
                                    >
                                        <p>{productStrings.addToCart}</p>
                                    </div>
                                </td> */}
                                <td
                                    className={[
                                        styles.addBtnViewStyle,
                                        ["Sold", "Paid Out", "Packed"].includes(
                                            item.availability_status
                                        ) && styles.disableBtnViewStyle,
                                    ].join(" ")}>
                                    <div
                                        onClick={
                                            ![
                                                "Sold",
                                                "Paid Out",
                                                "Packed",
                                            ].includes(item.availability_status)
                                                ? () => {
                                                      handleAddProductToCart(
                                                          item
                                                      );
                                                  }
                                                : () => {}
                                        }>
                                        <p>{productStrings.addToCart}</p>
                                    </div>
                                </td>

                                <td
                                    className={styles.deleteProductStyle}
                                    onClick={() => handleDeleteProduct(item)}>
                                    {productStrings.delete}
                                    {/* <RiDeleteBin7Fill /> */}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        );
    };

    const renderTableSection = () => {
        return (
            <div className={styles.tableSectionViewStyle}>
                {renderTableTopSection()}
                <div className={styles.bottomTableViewStyle}>
                    {renderTableBottomSection()}
                </div>
            </div>
        );
    };
    return (
        <div
            className={[
                pathname === "/products" && styles.containerStyle2,
                styles.containerStyle,
            ].join(" ")}>
            {renderTopSection()}
            {renderTableSection()}
        </div>
    );
};
export default Products;
