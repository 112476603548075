import React from "react";
import PropTypes from "prop-types";
import { checkIcon } from "resources/images/images";
import styles from "./style.module.css";
const CheckBox = (props) => {
    const {
        lableText,
        checked,
        onClick = () => {},
        customInput,
        customPercenatage,
    } = props;
    return (
        <div onClick={onClick} className={styles.containerStyle}>
            <div className={styles.containerInsideStyle}>
                <div className={styles.subContainerStyle}>
                    {checked && (
                        <img src={checkIcon} className={styles.imgStyle} />
                    )}
                </div>
                <p className={styles.chackBoxLableTextStyle}>{lableText}</p>
            </div>
            {customPercenatage && (
                <p className={styles.percentageTextStyle}>
                    {customPercenatage}
                </p>
            )}
        </div>
    );
};
CheckBox.propTypes = {
    lableText: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    customInput: PropTypes.string,
};
export default CheckBox;
