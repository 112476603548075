import React, { useState } from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";
import { dropdownIcon } from "resources/images/images";
import styles from "./styles.module.css";
const Dropdown = (props) => {
    const {
        onChange = () => {},
        openPopUp = () => {},
        value,
        selecterViewStyle: customViewStyle,
        containerViewStyle: customContainerViewStyle,
        options,
        lableText,
    } = props;

    const [isSelectFocused, setIsSelectFocused] = useState(false);

    const handleFocus = () => {
        setIsSelectFocused(true);
    };

    const handleBlur = () => {
        setIsSelectFocused(false);
    };

    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <DropDownIcon />
                </components.DropdownIndicator>
            )
        );
    };
    const DropDownIcon = () => (
        <div className={styles.dropDownIconViewStyle}>
            <img src={dropdownIcon} className={styles.imgStyle} />
        </div>
    );
    return (
        <div
            className={[
                styles.containerViewStyle,
                customContainerViewStyle,
            ].join(" ")}
        >
            <p className={styles.lableTextStyle}>{lableText}</p>
            <div
                className={`${styles.dropDownViewStyle} ${
                    isSelectFocused ? styles.containerFoused : ""
                }`}
                onFocus={handleFocus}
                onBlur={handleBlur}
            >
                <Select
                    value={value}
                    onChange={(text) => onChange(text)}
                    options={options}
                    components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator,
                    }}
                    className={[styles.selecterViewStyle, customViewStyle].join(
                        " "
                    )}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: "7px",
                            height: "47px",
                            borderColor: state.isFocused ? "black" : "gray",
                            // border: "none",
                            border: "0",
                            boxShadow: "none",
                            fontFamily: "Inter-Regular",
                            fontSize: "16px",
                        }),
                        option: (provided) => ({
                            ...provided,
                            fontFamily: "Inter-Regular",
                            fontSize: "16px",
                        }),
                    }}
                />
                <span className={styles.divider}></span>
                <div className={styles.addIconStyles}>
                    <button
                        onClick={openPopUp}
                        className={styles.customButtonStyle}
                    >
                        +
                    </button>
                </div>
            </div>
        </div>
    );
};

Dropdown.propTypes = {
    value: PropTypes.string,
    lableText: PropTypes.string,
    onChange: PropTypes.func,
    selecterViewStyle: PropTypes.string,
    containerViewStyle: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
};
export default Dropdown;
