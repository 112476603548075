import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuthContext } from "../hooks/useAuthContext";

const AuthGuard = (props) => {
    const { children } = props;
    const { isLoggedIn, isInitialized } = useAuthContext();

    let loginRoute = "/landing";

    if (!isInitialized) {
        return "Loading...";
    }

    if (!isLoggedIn) {
        return <Navigate to={loginRoute} />;
    }

    return <>{children}</>;
};

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default AuthGuard;
