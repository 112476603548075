import React, { createContext, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    /*show the success message*/
    const showToast = {
        success: (message) => {
            toast.success(
                <>
                    <p>{message}</p>
                </>,
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        },

        /*show the error message*/
        error: (message) => {
            toast.error(
                <>
                    <p>{message}</p>
                </>,
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
        },
    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            <ToastContainer />
        </ToastContext.Provider>
    );
};

export const useToast = () => {
    return useContext(ToastContext);
};
