import { getAllSellerDetailsApi } from "networking/apis/seller/getallsellerdetails";
import { getSellerDetailsApi } from "networking/apis/seller/getsellerdetails";
import React, { createContext, useEffect, useState } from "react";

export const SellerDataContext = createContext();

export const SellerDataProvider = (props) => {
    /*states*/
    const [allSellers, setAllSellers] = useState();

    /*onload the page get seller details*/

    useEffect(() => {
        handleGetAllSellerDeatils();
    }, []);

    /*handle get  all seller details*/

    const handleGetAllSellerDeatils = async () => {
        try {
            const getAllSellersResponse = await getAllSellerDetailsApi();
            if (getAllSellersResponse) {
                setAllSellers(getAllSellersResponse.data.data);
            }
        } catch (error) {
            // console.log("error in fetching sellers details", error);
        }
    };

    return (
        <SellerDataContext.Provider
            value={{ allSellers, handleGetAllSellerDeatils }}>
            {props.children}
        </SellerDataContext.Provider>
    );
};
