/*product strings*/

const productStrings = {
    sellerId: "id",
    brand: "Brand",
    category: "Category",
    model: "Model",
    material: "Material",
    color: "Color",
    size: "Size",
    price: "Price",
    consignmentPercent: "Consignment %",
    consignedPrice: "Consigned Price",
    status: "Status",
    createdDate: "Created Date",
    soldDate: "Sold Date",
    purchaseMethod: "Purchase Method",
    delete: "Delete",
    edit: "Edit",
    addToCart: "Add to cart",
    comment: "Comment",
};

/*seller strings*/

const sellerStrings = {
    sellerId: "ID",
    sellerName: "Name",
    sellerPhone: "Phone",
    sellerEmail: "Email",
    sellerMemberSince: "Member since",
    sellerCreatedDate: "Created date",
    sellerRegisterNo: "Reg number",
    sellerBankNo: "Bank number",
    sellerComment: "Comment",
};

export const strings = {
    productStrings,
    sellerStrings,
};
