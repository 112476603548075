import React from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";

const NotFoundPage = () => {
    const navigate = useNavigate();
    const { userType } = useAuthContext();
    return (
        <div className={styles.notFoundPageStyle}>
            <h1 className={styles.pageNotFoundHeadingStyle}>
                404 Page Not Found
            </h1>
            <p className={styles.redirectHeadingStyle}>
                Redirect To &nbsp;
                <span
                    className={styles.redirectSubHeadingStyle}
                    onClick={() =>
                        navigate(userType === "admin" ? "/adminpanel" : "/")
                    }>
                    Home Page
                </span>
            </p>
        </div>
    );
};

export default NotFoundPage;
