import React from "react";
import styles from "./styles.module.css";
import PropTypes from "prop-types";

const Button = (props) => {
    const {
        color = "primary",
        type = "button",
        onClick = () => {},
        title,
        btnStyle: customBtnStyle,
        customImgStyle,
        icon,
    } = props;
    let colorStyle = styles.primaryBtnStyle;
    if (color === "dark") {
        colorStyle = styles.darkBtnStyle;
    } else if (color === "light") {
        colorStyle = styles.whiteBtnStyle;
    }

    return (
        <button
            type={type}
            onClick={onClick}
            className={[styles.btnStyle, colorStyle, customBtnStyle].join(" ")}>
            {icon && (
                <div
                    className={[styles.iconViewStyle, customImgStyle].join(
                        " "
                    )}>
                    <img src={icon} className={styles.imgStyle} />
                </div>
            )}
            {title}
        </button>
    );
};

Button.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    color: PropTypes.oneOf(["primary", "dark", "light"]),
    btnStyle: PropTypes.string,
};

export default Button;
