import React, { useEffect, useState } from "react";
import Input from "components/input";
import AdminPanelCard from "components/cards/admin-panel-card";
import { storesData } from "resources/dummyData";
import Button from "components/button";
import { useAuthContext } from "hooks/useAuthContext";
import { storeLogo } from "resources/images/images";
import { getAdminPanelDataApi } from "networking/apis/admin/login";
import { useSpinner } from "providers/SpinnerProvider";
import styles from "./styles.module.css";

const AdminPanel = () => {
    // contexts:
    const { logout, user, adminLogin } = useAuthContext();
    const { setIsLoading } = useSpinner();

    // states:
    const [searchText, setSearchText] = useState("");
    const [adminStoresData, setAdminStoresData] = useState();
    const [filteredStoreData, setFilteredStoreData] = useState(adminStoresData);

    // functions:
    const filterData = (e) => {
        const searchText = e.target.value.toLowerCase();
        setSearchText(searchText);
        if (searchText === "") {
            // If search text is empty, reset filtered data to display all stores
            setFilteredStoreData(adminStoresData);
        } else {
            // Otherwise, filter stores based on search text
            setFilteredStoreData(
                adminStoresData.filter((store) =>
                    JSON.stringify(store).toLowerCase().includes(searchText)
                )
            );
        }
    };

    useEffect(() => {
        setFilteredStoreData(adminStoresData);
    }, [adminStoresData]);

    useEffect(() => {
        // if (user.authToken) {
        getAdminPanelData();
        // }
    }, []);

    const getAdminPanelData = async () => {
        setIsLoading(true);
        try {
            const response = await getAdminPanelDataApi();
            if (response.data.type === "success") {
                setIsLoading(false);
                setAdminStoresData(response.data.data);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error inn fetching products", error);
        }
    };

    const tapOnLogoutBtn = () => {
        logout();
    };

    const renderTopHeaderSection = () => {
        return (
            <div className={styles.headerContainerStyles}>
                <div className={styles.leftHeadingViewStyles}>
                    <p className={styles.adminTextStyles}>Admin</p>
                    <div className={styles.dividerStyles}></div>
                    <p className={styles.storesTextStyles}>Stores</p>
                </div>
                <div className={styles.inputAndLogOutViewStyles}>
                    <Input
                        type="text"
                        name="search"
                        customInputStyle={styles.searchInputStyles}
                        value={searchText}
                        onChange={(e) => filterData(e)}
                        placeholder="Search"
                    />
                    <Button
                        title="Logout"
                        btnStyle={styles.logOutBtnStyles}
                        onClick={() => tapOnLogoutBtn()}
                    />
                </div>
            </div>
        );
    };

    const renderCityDataSection = (cityName) => {
        return (
            <div className={styles.storeCityDataViewStyles}>
                <div className={styles.borderStyles}></div>
                <div className={styles.cityDescViewStyles}>
                    <p className={styles.cityHeadingStyles}>City:</p>
                    <p className={styles.cityNameStyles}>{cityName}</p>
                </div>
            </div>
        );
    };

    const renderBottomStoreDataSection = () => {
        const storesByCity = {};
        filteredStoreData?.forEach((item) => {
            const city = item.storeData?.city?.toUpperCase();
            if (!storesByCity[city]) {
                storesByCity[city] = [];
            }
            storesByCity[city].push(item);
        });

        if (Object.keys(storesByCity).length === 0) {
            return (
                <div className={styles.noDataFoundStyles}>No Data found.</div>
            );
        }

        return (
            <div className={styles.bottomMainBlockStyles}>
                {Object.entries(storesByCity).map(([city, stores]) => (
                    <div key={city}>
                        {/* Render city name here */}
                        {renderCityDataSection(city)}
                        <div className={styles.storeDataBlockStyles}>
                            {stores.map((store, index) => {
                                // Ensure store and productsData are defined
                                if (!store || !store.productsData) return null;

                                // Filter products based on conditions
                                const filteredProducts =
                                    store.productsData.filter(
                                        (product) =>
                                            product &&
                                            product.availability_status ===
                                                "InStore" &&
                                            !product.deleted_at &&
                                            !product.sold_at
                                    );

                                const soldProducts = store.productsData.filter(
                                    (product) =>
                                        product &&
                                        product.availability_status ===
                                            "Sold" &&
                                        !product.deleted_at &&
                                        product.sold_at
                                );

                                // Calculate total sales price for products sold today
                                const toDaySalesPrice = soldProducts.reduce(
                                    (totalPrice, product) => {
                                        const soldDate = new Date(
                                            product.sold_at
                                        );
                                        const currentDate = new Date();
                                        if (
                                            soldDate.toLocaleDateString(
                                                "en-GB",
                                                {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "2-digit",
                                                }
                                            ) ===
                                            currentDate.toLocaleDateString(
                                                "en-GB",
                                                {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "2-digit",
                                                }
                                            )
                                        ) {
                                            // Remove commas from product price before parsing
                                            const priceWithoutCommas =
                                                product.price.replace(/,/g, "");
                                            return (
                                                totalPrice +
                                                parseInt(priceWithoutCommas)
                                            );
                                        }
                                        return totalPrice;
                                    },
                                    0
                                );

                                // Calculate last month's sales price for sold products
                                const lastMonthSalesPrice = soldProducts.reduce(
                                    (totalPrice, product) => {
                                        const soldDate = new Date(
                                            product.sold_at
                                        );
                                        const currentDate = new Date();
                                        const lastMonth = new Date(
                                            currentDate.getFullYear(),
                                            currentDate.getMonth() - 1
                                        );

                                        if (
                                            soldDate.getMonth() ===
                                                lastMonth.getMonth() &&
                                            soldDate.getFullYear() ===
                                                lastMonth.getFullYear()
                                        ) {
                                            // Remove commas from product price before parsing
                                            const priceWithoutCommas =
                                                product.price.replace(/,/g, "");
                                            return (
                                                totalPrice +
                                                parseInt(priceWithoutCommas)
                                            );
                                        }
                                        return totalPrice;
                                    },
                                    0
                                );

                                return (
                                    <AdminPanelCard
                                        key={index}
                                        storeLogo={
                                            store.storeData.storeLogo ||
                                            storeLogo
                                        }
                                        storeName={store.storeData.store_name}
                                        storeAddress={`${store.storeData.address}, ${store.storeData.phone_number}`}
                                        nameOfStoreManager={
                                            store.storeData.store_name
                                        }
                                        storeManagerEmail={
                                            store.storeData.email
                                        }
                                        onClickViewMore={() => {}}
                                        noOfSellers={store.sellersData.length}
                                        noOfProductsInStore={
                                            filteredProducts.length
                                        }
                                        noOfProductsSold={soldProducts.length}
                                        toDaySalesPrice={`${toDaySalesPrice} DKK`}
                                        lastMonthSalesPrice={`${lastMonthSalesPrice} DKK`}
                                    />
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={styles.adminPanelContainerStyles}>
            {renderTopHeaderSection()}
            {renderBottomStoreDataSection()}
        </div>
    );
};

export default AdminPanel;
