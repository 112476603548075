import React, { createContext, useEffect, useState } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { getCartProductsApi } from "networking/apis/cart/getcartproducts";
import { addProductToCartApi } from "networking/apis/cart/addproducttocart";
import { useCartDataContext } from "hooks/useCartDataContext";
import { useSellerDataContext } from "hooks/useSellerDataContext";

export const CartDataContext = createContext();

export const CartDataProvider = (props) => {
    /*context*/
    const { storeData } = useAuthContext();

    /*states*/
    const [cartData, setCartData] = useState();
    const [update, setUpdate] = useState(true);

    /*get cart products if there is store data*/

    useEffect(() => {
        if (storeData) {
            handleGetCartProducts();
        }
    }, [storeData]);

    /*handleGetCartProducts*/

    const handleGetCartProducts = async () => {
        try {
            const getCartProductsResponse = await getCartProductsApi(
                storeData?.store?._id
            );

            setCartData(getCartProductsResponse.data.data.items);
        } catch (error) {
            console.log("error", error.toString());
        }
    };

    /*handleAddProductToCart*/

    const handleAddProductToCart = async (product) => {
        try {
            let addProductToCartData = {
                store_id: product.store_id,
                product: product._id,
            };
            const addProductToCartResponse = await addProductToCartApi(
                addProductToCartData
            );
            if (addProductToCartResponse.data.type === "success") {
                alert(addProductToCartResponse.data.message);
                handleGetCartProducts();
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    return (
        <CartDataContext.Provider
            value={{
                cartData,
                handleGetCartProducts,
                handleAddProductToCart,
                update,
                setUpdate,
            }}
        >
            {props.children}
        </CartDataContext.Provider>
    );
};
