import React, { createContext, useContext, useEffect, useState } from "react";
import { useToast } from "./ToastProvider";

export const NetWorkContext = createContext();

export const NetWorkProvider = ({ children }) => {
    const [isOnLine, setIsOnLine] = useState(navigator.onLine);
    const [isInitialised, setIsInitialised] = useState(true);
    const { showToast } = useToast();

    useEffect(() => {
        const handleOnlineStatusChange = () => {
            setIsOnLine(navigator.onLine);
            if (!isInitialised) {
                if (navigator.onLine) {
                    showToast.success("You are Online");
                } else {
                    setTimeout(() => {
                        showToast.error("You are Offline");
                    }, 1000);
                }
            }
        };
        window.addEventListener("online", handleOnlineStatusChange);
        window.addEventListener("offline", handleOnlineStatusChange);

        return () => {
            window.removeEventListener("online", handleOnlineStatusChange);
            window.removeEventListener("offline", handleOnlineStatusChange);
        };
    }, [isInitialised, showToast]);

    useEffect(() => {
        setIsInitialised(false);
    }, []);

    return (
        <NetWorkContext.Provider value={{ isOnLine }}>
            {children}
        </NetWorkContext.Provider>
    );
};

export const useNetWorkContext = () => {
    return useContext(NetWorkContext);
};
