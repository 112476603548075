import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "hooks/useAuthContext";
import Input from "components/input";
import Button from "components/button";
import styles from "./styles.module.css";
const AdminLogin = () => {
    /*context*/
    const { adminLogin, setErrorMessage, errorMessage } = useAuthContext();

    /*routing*/
    const navigate = useNavigate();

    /*states*/
    const [actionLoading, setActionLoading] = useState(false);

    /*Login validation schema*/

    const signUpSchema = Yup.object().shape({
        email: Yup.string()
            .email("Enter a valid email address")
            .required("Email is required"),
        password: Yup.string().required("Password is required"),
    });

    /*handle login*/

    const handleAdminLogin = async (values, { resetForm }) => {
        setActionLoading(true);
        await adminLogin(values);
        resetForm();
        setActionLoading(false);
    };

    /*initialization of formik values*/

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: signUpSchema,
        onSubmit: handleAdminLogin,
    });

    const renderInputSection = () => {
        return (
            <div className={styles.inputSectionStyle}>
                <p className={styles.titleTextStyle}>Login</p>
                <div className={styles.inputSubSectionStyle}>
                    <div className={styles.emailAndPasswordInputStyle}>
                        <Input
                            name="email"
                            lableText={"Email"}
                            placeholder={"Enter your email"}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onFocus={() => {
                                formik.setFieldError("email", "");
                                setErrorMessage("");
                            }}
                            onBlur={formik.handleBlur}
                            hasError={
                                formik.touched.email && formik.errors.email
                                    ? formik.errors.email
                                    : ""
                            }
                            customInputStyle={styles.emailInputStyles}
                        />
                        <Input
                            name="password"
                            type="password"
                            lableText={"Password"}
                            placeholder={"Enter Password"}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onFocus={() => {
                                formik.setFieldError("password", "");
                                setErrorMessage("");
                            }}
                            onBlur={formik.handleBlur}
                            hasError={
                                formik.touched.password &&
                                formik.errors.password
                                    ? formik.errors.password
                                    : ""
                            }
                        />
                    </div>
                    {errorMessage && (
                        <p className={styles.erroMessageStyles}>
                            {errorMessage}
                        </p>
                    )}
                </div>
            </div>
        );
    };

    const renderForgetPasswordSection = () => {
        return (
            <div className={styles.fpSectionStyle}>
                <p
                    onClick={() => navigate("/forgotpassword")}
                    className={styles.fpTextStyle}>
                    Forgotten password
                </p>
            </div>
        );
    };

    const renderButtonSection = () => {
        return (
            <div className={styles.btnSectionStyle}>
                <Button
                    type="submit"
                    title={actionLoading ? "Loading..." : "Login"}
                    btnStyle={styles.btnViewStyle}
                />
            </div>
        );
    };

    return (
        <div className={styles.containerStyle}>
            <form
                onSubmit={formik.handleSubmit}
                className={styles.subContainerStyle}>
                {renderInputSection()}
                {/* {renderForgetPasswordSection()} */}
                {renderButtonSection()}
            </form>
        </div>
    );
};
export default AdminLogin;
