import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./style.module.css";
import { useAuthContext } from "hooks/useAuthContext";
import { productReportApi } from "networking/apis/report/report";
import { useSpinner } from "providers/SpinnerProvider";
import { strings } from "resources/strings/english-string";
const Reports = () => {
    /*contexts*/
    const { storeData } = useAuthContext();
    const { setIsLoading } = useSpinner();

    /*strings*/
    const { productStrings } = strings;

    /*routing*/
    const navigate = useNavigate();
    const { pathname } = useLocation();

    /*states*/
    const [productReport, setProductReport] = useState();

    /*onload the page get product report details*/
    useEffect(() => {
        handleProductReport();
    }, []);

    /*handleProductReport*/

    const handleProductReport = async () => {
        setIsLoading(true);
        try {
            const getProductReportResponse = await productReportApi(
                storeData?.store?._id
            );
            if (getProductReportResponse.data.type === "success") {
                // console.log(
                //     "getProductReportResponse",
                //     getProductReportResponse
                // );
                setIsLoading(false);
                setProductReport(getProductReportResponse.data.products);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };
    const renderTableTopSection = () => {
        return (
            <table>
                <thead>
                    <tr className={styles.headingStyle}>
                        <td className={styles.headingStyle2}>
                            {productStrings.sellerId}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.brand}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.category}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.model}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.material}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.color}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.size}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.price}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.status}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.soldDate}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.purchaseMethod}
                        </td>
                        <td className={styles.headingStyle2}></td>
                    </tr>
                </thead>
            </table>
        );
    };

    const renderTableBottomSection = () => {
        return (
            <table>
                <tbody>
                    {productReport &&
                        productReport.map((item, index) => (
                            <tr
                                key={index}
                                className={styles.assetListDataRowStyle}>
                                <td className={styles.serialNoStyle}>
                                    <p
                                        className={[
                                            styles.serialNoTextStyle,
                                            productReport.length === 1 &&
                                                styles.serialNoTextStyle2,
                                        ].join(" ")}>
                                        {item.product_uid}
                                    </p>
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {/* {item.brand} */}
                                    {item.brand ? item.brand : "N/A"}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.category ? item.category : "N/A"}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.model ? item.model : "N/A"}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.material ? item.material : "N/A"}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.colour ? item.colour : "N/A"}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.size ? item.size : "N/A"}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {/* {item.price} */}
                                    {item.price ? item.price : "N/A"}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    <p
                                        className={
                                            item.availability_status ===
                                            "InStore"
                                                ? styles.statusInStoreViewStyle
                                                : item.availability_status ===
                                                  "Sold"
                                                ? styles.statusSoldViewStyle
                                                : item.availability_status ===
                                                  "Paid Out"
                                                ? styles.statusPaidOutViewStyle
                                                : item.availability_status ===
                                                  "Packed"
                                                ? styles.statusPackedViewStyle
                                                : ""
                                        }>
                                        {item.availability_status}
                                    </p>
                                </td>
                                <td className={styles.createdAtTextStyle}>
                                    {new Date(item.sold_at).toLocaleDateString(
                                        "pt-PT"
                                    )}
                                </td>
                                <td
                                    className={[
                                        styles.editBtnViewStyle,
                                        item.status === "Sold" &&
                                            styles.disableBtnViewStyle,
                                    ].join(" ")}>
                                    {item.payment_method}
                                </td>
                                <td
                                    className={[
                                        styles.addBtnViewStyle,
                                        item.status === "Sold" &&
                                            styles.disableBtnViewStyle,
                                    ].join(" ")}></td>
                            </tr>
                        ))}
                </tbody>
            </table>
        );
    };

    const renderTableSection = () => {
        return (
            <div className={styles.tableSectionViewStyle}>
                {renderTableTopSection()}
                <div className={styles.bottomTableViewStyle}>
                    {renderTableBottomSection()}
                </div>
            </div>
        );
    };

    return <div className={styles.containerStyle}>{renderTableSection()}</div>;
};
export default Reports;
