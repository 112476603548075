import React from "react";
import styles from "./styles.module.css";
import Button from "components/button";

const AdminPanelCard = (props) => {
    const {
        storeLogo,
        storeName,
        storeAddress,
        nameOfStoreManager,
        storeManagerEmail,
        onClickViewMore = () => {},
        noOfSellers,
        noOfProductsInStore,
        noOfProductsSold,
        toDaySalesPrice,
        lastMonthSalesPrice,
    } = props;

    const renderLeftSection = () => {
        return (
            <div className={styles.leftBlockStyles}>
                <div className={styles.topLogoStyles}>
                    <img
                        src={storeLogo}
                        alt="logo"
                        className={styles.imageStyles}
                    />
                </div>

                <div className={styles.bottomContentViewStyles}>
                    <div className={styles.storeDescriptionViewStyles}>
                        <p className={styles.storeNameTextStyles}>
                            {storeName}
                        </p>
                        <p className={styles.storeDescTextStyles}>
                            {storeAddress}
                        </p>
                    </div>

                    <div className={styles.storeDeatilsViewStyles}>
                        <p className={styles.storeManagerHeadingStyles}>
                            Store Manager: &nbsp;
                            <span className={styles.storeManagerNameTextStyles}>
                                {nameOfStoreManager}
                            </span>
                        </p>

                        <p className={styles.storeEmailHeadingStyles}>
                            Email: &nbsp;
                            <span className={styles.storeEmailTextStyles}>
                                {storeManagerEmail}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const renderRightSection = () => {
        return (
            <div className={styles.rightBlockStyles}>
                <Button
                    title="View more"
                    onClick={onClickViewMore}
                    btnStyle={styles.viewMoreBtnStyles}
                />

                <div className={styles.storeAndProductDataBlockStyles}>
                    <div className={styles.storeDataBlockStyles}>
                        <div className={styles.noOfSellersViewStyles}>
                            <p className={styles.noOfSellersHeadingStyles}>
                                Number of seller:
                            </p>
                            <p className={styles.noOfSellersCountTextStyles}>
                                {noOfSellers}
                            </p>
                        </div>

                        <div className={styles.productDataBlockStyles}>
                            <div className={styles.productInStoreViewStyles}>
                                <p
                                    className={
                                        styles.productsInStoreHeadingStyles
                                    }>
                                    Products in-store:
                                </p>
                                <p className={styles.productInNumTextStyles}>
                                    {noOfProductsInStore}
                                </p>
                            </div>
                            <div className={styles.productsSoldViewStyles}>
                                <p className={styles.productsSoldHeadingStyles}>
                                    Products sold:
                                </p>
                                <p className={styles.productSoldNumTextStyles}>
                                    {noOfProductsSold}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.salesDataBlockStyles}>
                        <p className={styles.salesHeadingStyles}>Sales</p>

                        <div className={styles.salesStasticsBlockStyles}>
                            <div className={styles.salesToDayViewStyles}>
                                <p className={styles.toDayHeadingStyles}>
                                    Today:
                                </p>
                                <p className={styles.toDaySalesPriceTextStyles}>
                                    {toDaySalesPrice}
                                </p>
                            </div>
                            <div className={styles.salesLastMonthViewStyles}>
                                <p className={styles.lastMonthHeadingStyles}>
                                    Last month:
                                </p>
                                <p
                                    className={
                                        styles.lastMonthSalesPriceTextStyles
                                    }>
                                    {lastMonthSalesPrice}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.adminPanelCardContainerStyles}>
            {renderLeftSection()}
            {renderRightSection()}
        </div>
    );
};

export default AdminPanelCard;
