export const jwtDecode = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export const getDataFromToken = async (token) => {
  const decoded = await jwtDecode(token);
  return decoded;
};

export const getUserIdFromToken = async (token) => {
  const user = await jwtDecode(token);
  return user.id;
};

export const getUserTypeFromToken = async (token) => {
  const user = await jwtDecode(token);
  return user.user_type;
};

export const isTokenExpired = (token) => {
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};
