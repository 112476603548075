import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.css";

const TextArea = (props) => {
  const {
    type,
    name,
    value,
    onChange = () => {},
    placeholder,
    hasError,
    lableText,
    lableTextStyle,
    customInputStyle,
    customInputViewStyle,
    customRightIconViewStyle,
    customErrorTextStyle,
    rightIcon,
    ...rest
  } = props;

  return (
    <div className={styles.inputContainerStyle}>
      {lableText && (
        <p className={[styles.lableTextStyle, lableTextStyle].join(" ")}>
          {lableText}
        </p>
      )}
      <div
        className={[styles.inputSubContainerStyle, customInputViewStyle].join(
          " "
        )}
      >
        <textarea
          name={name}
          className={[styles.inputStyle, customInputStyle].join(" ")}
          value={value}
          onChange={onChange}
          placeholder={placeholder || ""}
          {...rest}
        ></textarea>
      </div>
      {hasError && (
        <p className={[styles.errorTextStyle, customErrorTextStyle].join(" ")}>
          {hasError}
        </p>
      )}
    </div>
  );
};

TextArea.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  hasError: PropTypes.string,
  lableText: PropTypes.string,
  lableTextStyle: PropTypes.string,
  customErrorTextStyle: PropTypes.string,
};
export default TextArea;
