import Button from "components/button";
import Input from "components/input";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { personBlackIcon, plus, search } from "resources/images/images";
import styles from "./style.module.css";
import { getAllSellerDetailsApi } from "networking/apis/seller/getallsellerdetails";
import { useSpinner } from "providers/SpinnerProvider";
import { strings } from "resources/strings/english-string";
const Sellers = () => {
    /*contexts*/
    const { setIsLoading } = useSpinner();

    /*strings*/
    const { sellerStrings } = strings;

    /*routing*/
    const { pathname } = useLocation();
    const navigation = useNavigate();

    /*states*/
    const [searchText, setSearchText] = useState("");
    const [allSellers, setAllSellers] = useState([]);
    const [filteredSellers, setFilteredSellers] = useState(allSellers);

    /*useEffect*/

    /*filter the all sellers based on their titles and data*/

    useEffect(() => {
        setFilteredSellers(allSellers);
        // console.log(allSellers);
    }, [allSellers]);

    /*onload the page get all seller details*/

    useEffect(() => {
        handleGetAllSellerDeatils();
    }, []);

    const filterProduct = (e) => {
        setSearchText(e.target.value);
        setFilteredSellers(
            allSellers.filter(
                (seller) =>
                    // JSON.stringify(seller)
                    //     .toLowerCase()
                    //     .includes(e.target.value.toLowerCase())
                    seller.seller_uid
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()) ||
                    seller.seller_name
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()) ||
                    seller.email
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
            )
        );
    };

    /*send seller details to add product page disaply the seller details input section*/
    const tapOnEditProduct = (seller) => {
        const selllerData = {
            availability_status: "InStore",
            brand: "",
            category: "",
            colour: "",
            comment: "",
            consignment: "",

            material: "",
            model: "",
            price: "",
            product_uid: "",
            seller_id: {
                seller_uid: seller.seller_uid,
                seller_name: seller.seller_name,
                _id: seller._id,
            },
            size: "",
        };
        navigation("/addProduct", {
            state: { data: selllerData, type: "add" },
        });
    };

    /*handle get  all seller details*/
    const handleGetAllSellerDeatils = async () => {
        setIsLoading(true);
        try {
            const getAllSellersResponse = await getAllSellerDetailsApi();
            if (getAllSellersResponse.data.type === "success") {
                setIsLoading(false);
                setAllSellers(getAllSellersResponse.data.data);
            } else {
                setIsLoading(false);
            }
        } catch {
            setIsLoading(false);
            console.log("error in fetching sellers details");
        }
    };

    const renderTopSection = () => {
        return (
            <div className={styles.topSectionStyle}>
                <div className={styles.inputSectionStyle}>
                    <Input
                        placeholder="Search here"
                        value={searchText}
                        onChange={(e) => filterProduct(e)}
                        customInputStyle={styles.inputStyle}
                        customInputViewStyle={styles.customInputViewStyle}
                        customRightIconViewStyle={
                            styles.customRightIconViewStyle
                        }
                        rightIcon={search}
                    />
                </div>
                <Button
                    title="Add Seller"
                    icon={plus}
                    onClick={() => navigation("/addSeller")}
                    btnStyle={styles.btnViewStyle}
                />
            </div>
        );
    };

    const renderTableTopSection = () => {
        return (
            <table>
                <thead>
                    <tr className={styles.headingStyle}>
                        <td className={styles.iDHeadingStyle}>
                            {sellerStrings.sellerId}
                        </td>
                        <td className={styles.headingStyle2}>
                            {sellerStrings.sellerName}
                        </td>
                        <td className={styles.headingStyle2}>
                            {sellerStrings.sellerPhone}
                        </td>
                        <td className={styles.headingStyle2}>
                            {sellerStrings.sellerEmail}
                        </td>
                        <td className={styles.headingStyle2}>
                            {sellerStrings.sellerCreatedDate}
                        </td>
                        <td className={styles.headingStyle2}></td>
                        <td className={styles.headingStyle2}></td>
                    </tr>
                </thead>
            </table>
        );
    };

    const renderTableBottomSection = () => {
        return (
            <table>
                <tbody>
                    {filteredSellers &&
                        [...filteredSellers].reverse().map((item, index) => (
                            <tr
                                key={index}
                                className={styles.assetListDataRowStyle}
                            >
                                <td className={styles.serialNoStyle}>
                                    <p
                                        className={[
                                            styles.serialNoTextStyle,
                                            filteredSellers.length === 1 &&
                                                styles.serialNoTextStyle2,
                                        ].join(" ")}
                                    >
                                        {item.seller_uid}
                                    </p>
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.seller_name}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.phone_number}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.email}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {new Date(
                                        item.created_at
                                    ).toLocaleDateString("pt-PT")}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    <Button
                                        title="Add new product"
                                        onClick={() => tapOnEditProduct(item)}
                                        icon={plus}
                                        btnStyle={styles.editBtnViewStyle}
                                    />
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    <Button
                                        title="View profile"
                                        onClick={() =>
                                            navigation("/profile", {
                                                state: item,
                                            })
                                        }
                                        icon={personBlackIcon}
                                        btnStyle={
                                            styles.viewProfileBtnViewStyle
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        );
    };

    const renderTableSection = () => {
        return (
            <div className={styles.tableSectionViewStyle}>
                {renderTableTopSection()}
                <div className={styles.bottomTableViewStyle}>
                    {renderTableBottomSection()}
                </div>
            </div>
        );
    };
    return (
        <div className={styles.containerStyle}>
            {renderTopSection()}
            {renderTableSection()}
        </div>
    );
};
export default Sellers;
