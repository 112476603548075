import React, { useState } from "react";
import Button from "components/button";
import { useToast } from "providers/ToastProvider";
import { useSpinner } from "providers/SpinnerProvider";
import { verifyStoreApi } from "networking/apis/store/verifystore";
import styles from "./styles.module.css";

const VerifyStore = () => {
    /*Toaster and spinner */
    const { showToast } = useToast();
    const { setIsLoading } = useSpinner();
    const [verifyToken, setVerifyToken] = useState(
        window.location.pathname.split("/")[2]
    );

    /* Handle password reset */

    const handleVerifyStore = async () => {
        setIsLoading(true);
        try {
            const verifyStoreResponse = await verifyStoreApi({
                token: verifyToken,
            });

            if (verifyStoreResponse.data.type === "success") {
                console.log("values", verifyStoreResponse);
                setIsLoading(false);
                showToast.success(verifyStoreResponse.data.message);
            } else if (
                verifyStoreResponse.data.type === "fail" &&
                verifyStoreResponse.status === 400
            ) {
                setIsLoading(false);
                showToast.error(verifyStoreResponse.data.message);
            } else {
                setIsLoading(false);
                showToast.error(verifyStoreResponse.data.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };

    return (
        <div className={styles.verifyStoreContainerStyles}>
            {/* <div className={styles.storeDetailsStyles}>
                <h1 className={styles.storeHeadingStyles}>
                    Details of the store
                </h1>
                <p className={styles.storeDetailsTextStyles}>
                    Name of the store:
                    <span className={styles.storeDescStyles}></span>
                </p>
                <p className={styles.storeDetailsTextStyles}>
                    Store email id:
                    <span className={styles.storeDescStyles}></span>
                </p>
                <p className={styles.storeDetailsTextStyles}>
                    Store contact number:
                    <span className={styles.storeDescStyles}></span>
                </p>
            </div> */}
            <div className={styles.verifyButtonStyles}>
                <Button
                    title="Verify Store"
                    onClick={handleVerifyStore}
                    btnStyle={styles.verifyButtonStyles}
                />
            </div>
        </div>
    );
};

export default VerifyStore;
