import { loginApi } from "networking/apis/store/login";
import { registrationApi } from "networking/apis/store/registration";
import React, {
    createContext,
    useState,
    useMemo,
    useCallback,
    useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { setAxiosSession } from "utils/auth";
import { getDataFromToken, isTokenExpired } from "utils/jwt";
import { useSpinner } from "./SpinnerProvider";
import { adminLoginApi } from "networking/apis/admin/login";

const AuthContext = createContext();

const AuthProvider = (props) => {
    /*contexts*/
    const { setIsLoading } = useSpinner();

    /*routing*/
    const navigate = useNavigate();

    /*states*/
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [userType, setUserType] = useState(null);
    const [authError, setAuthError] = useState(null);
    const [isSignUp, setIsSignUp] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [storeData, setStoreData] = useState();

    // admin states:

    const initialize = useCallback(async () => {
        try {
            const loggedIn = localStorage.getItem("loggedIn");
            setIsLoggedIn(loggedIn);

            setIsInitialized(true);
            const token = localStorage.getItem("accessToken");
            setUserType(localStorage.getItem("userType"));
            if (token && isTokenExpired(token)) {
                setAxiosSession(token);
                const tokenData = await getDataFromToken(token);
                setIsInitialized(true);
            } else {
                setIsInitialized(true);
                // setIsLoggedIn(false);
            }
        } catch (error) {
            setIsInitialized(true);
            setIsLoggedIn(false);
            setAuthError(error.message);
        }
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize, storeData]);

    /*handle store login*/

    const storeLogin = async (loginData) => {
        setIsLoading(true);
        try {
            setAuthError(null);
            let response = await loginApi(loginData);
            const { type, data, authToken, message } = response.data;

            if (type === "success") {
                setIsLoading(false);
                localStorage.setItem("accessToken", authToken);
                localStorage.setItem("loggedIn", true);
                localStorage.setItem("userType", "store");
                localStorage.setItem(
                    "storeData",
                    JSON.stringify(response.data)
                );
                setStoreData(response.data);
                setIsLoggedIn(true);
                setUser(data);
                setUserType("store");
                navigate("/products");
            } else {
                setIsLoading(false);
                setUser(null);
                setAuthError(message);
                setIsLoggedIn(false);
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            setIsLoading(false);
            setUser(null);
            setAuthError(error.message);
            setIsLoggedIn(false);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("storeData")) {
            setStoreData(JSON.parse(localStorage.getItem("storeData")));
        }
    }, [localStorage.getItem("storeData")]);

    /*handle store register*/

    const storeRegister = async (signUpData) => {
        setIsLoading(true);
        try {
            setAuthError(null);
            let response = await registrationApi(signUpData);
            const { type, data, token, message } = response.data;
            if (type === "success") {
                setIsLoading(false);
                setIsSignUp(true);
            } else {
                setIsLoading(false);
                setUser(null);
                setAuthError(message);
                setIsLoggedIn(false);
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            setIsLoading(false);
            setUser(null);
            setAuthError(error.message);
            setIsLoggedIn(false);
            setIsSignUp(false);
        }
    };

    /*handle admin login*/

    const adminLogin = async (loginData) => {
        setIsLoading(true);
        try {
            setAuthError(null);
            let response = await adminLoginApi(loginData);
            const { type, authToken, msg } = response.data;

            if (type === "success") {
                setIsLoading(false);
                localStorage.setItem("accessToken", authToken);
                localStorage.setItem("loggedIn", true);
                localStorage.setItem("userType", "admin");
                localStorage.setItem(
                    "adminPanelData",
                    JSON.stringify(response.data)
                );
                setUser(response.data);
                setIsLoggedIn(true);
                setUserType("admin");
                navigate("/adminpanel");
            } else {
                setIsLoading(false);
                setAuthError(msg);
                setIsLoggedIn(false);
                setErrorMessage(msg);
            }
        } catch (error) {
            setIsLoading(false);
            setAuthError(error.message);
            setIsLoggedIn(false);
        }
    };

    /*handle logout*/

    const logout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("storeData");
        localStorage.removeItem("userType");
        localStorage.removeItem("adminPanelData");
        setUser(null);
        setAxiosSession(null);
        setIsLoggedIn(false);
        setUserType(null);
    };

    const memoizedValue = useMemo(
        () => ({
            isInitialized,
            isLoggedIn,
            user,
            userType,
            authError,
            setAuthError,
            setIsLoggedIn,
            storeLogin,
            storeData,
            setStoreData,
            storeRegister,
            logout,
            isSignUp,
            setIsSignUp,
            errorMessage,
            setErrorMessage,
            adminLogin,
        }),
        [
            isInitialized,
            isLoggedIn,
            user,
            userType,
            authError,
            setAuthError,
            setIsLoggedIn,
            storeLogin,
            storeData,
            setStoreData,
            storeRegister,
            logout,
            isSignUp,
            setIsSignUp,
            errorMessage,
            setErrorMessage,
            adminLogin,
        ]
    );

    return (
        <AuthContext.Provider value={memoizedValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
