import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../components/button";
import Input from "../../components/input";
import { useAuthContext } from "hooks/useAuthContext";
import styles from "./style.module.css";
import Modal from "components/modal";
const SignUp = () => {
    /*context*/
    const { isSignUp, setIsSignUp, setErrorMessage, errorMessage } =
        useAuthContext();
    const { storeRegister } = useAuthContext();
    const [isStaticPopupOpen, setStaticPopupOpen] = useState(false);

    /*routing*/
    const navigate = useNavigate();

    /*based on reference  onclick outside close the popup*/
    const popupRef = useRef(null);

    // function to close static popup onClick outside

    const handleClickOutsideStaticPopup = (event) => {
        if (isStaticPopupOpen) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setStaticPopupOpen(false);
            }
        }
    };

    /*states*/
    const [actionLoading, setActionLoading] = useState(false);

    /*formik validation schemas*/
    const signUpSchema = Yup.object().shape({
        store_name: Yup.string().required("Store name is required"),
        city: Yup.string().required("City name is required"),
        address: Yup.string().required("Address is required"),
        email: Yup.string()
            .email("Enter a valid email address")
            .required("Email is required"),
        phone_number: Yup.string()
            .required("Phone number required")
            .matches(/^[0-9]+$/, "Phone number must contain only digits"),
        password: Yup.string().required("Password is required"),
        repeat_password: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Repeat Password is required"),
    });

    /*handleSignUp*/

    const handleSignUp = async (values, { resetForm }) => {
        if (values.password === values.repeat_password) {
            let signUpData = {
                store_name: values.store_name,
                city: values.city,
                address: values.address,
                email: values.email,
                phone_number: values.phone_number,
                website_url: values.website_url,
                instagram_url: values.instagram_url,
                password: values.password,
            };

            try {
                // Make the API call
                await storeRegister(signUpData);
                setStaticPopupOpen(true);
                resetForm();
                setActionLoading(false);
                setStaticPopupOpen(true);
            } catch (error) {
                // Handle API call error and set an error message
                console.error("API call error:", error);
                setErrorMessage("An error occurred during sign-up.");
            }
        } else {
            console.log("Password is not matched");
        }
    };

    const closeModal = () => {
        setStaticPopupOpen(false);
    };

    /*initialization of formik values*/
    const formik = useFormik({
        initialValues: {
            store_name: "",
            city: "",
            address: "",
            email: "",
            phone_number: "",
            website_url: "",
            instagram_url: "",
            password: "",
            repeat_password: "",
        },
        validationSchema: signUpSchema,
        onSubmit: handleSignUp,
    });

    const renderProfileSection = () => {
        return (
            <div className={styles.eachSectionStyle}>
                <p className={styles.eachSectionTitleTextStyle}>Profile</p>
                <div className={styles.inputSectionStyle}>
                    <Input
                        name="store_name"
                        lableText={"Name of store"}
                        placeholder={"Enter name of store"}
                        value={formik.values.store_name}
                        onChange={formik.handleChange}
                        onFocus={() => {
                            formik.setFieldError("store_name", "");
                            setErrorMessage("");
                        }}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.store_name &&
                            formik.errors.store_name
                                ? formik.errors.store_name
                                : ""
                        }
                    />
                    <Input
                        name="city"
                        lableText={"City"}
                        placeholder={"Enter city"}
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        onFocus={() => {
                            formik.setFieldError("city", "");
                            setErrorMessage("");
                        }}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.city && formik.errors.city
                                ? formik.errors.city
                                : ""
                        }
                    />
                    <Input
                        name="address"
                        lableText={"Address"}
                        placeholder={"Enter your address"}
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onFocus={() => {
                            formik.setFieldError("address", "");
                            setErrorMessage("");
                        }}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.address && formik.errors.address
                                ? formik.errors.address
                                : ""
                        }
                    />
                </div>
            </div>
        );
    };

    const renderContactSection = () => {
        return (
            <div className={styles.eachSectionStyle}>
                <p className={styles.eachSectionTitleTextStyle}>
                    Contact infromaton
                </p>
                <div className={styles.inputSectionStyle}>
                    <Input
                        name="email"
                        lableText={"Email"}
                        placeholder={"Enter email ID"}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onFocus={() => {
                            formik.setFieldError("email", "");
                            setErrorMessage("");
                        }}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.email && formik.errors.email
                                ? formik.errors.email
                                : ""
                        }
                    />
                    <Input
                        name="phone_number"
                        lableText={"Phone number"}
                        placeholder={"Enter phone number"}
                        value={formik.values.phone_number}
                        onChange={formik.handleChange}
                        onFocus={() => {
                            formik.setFieldError("phone_number", "");
                            setErrorMessage("");
                        }}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.phone_number &&
                            formik.errors.phone_number
                                ? formik.errors.phone_number
                                : ""
                        }
                    />
                </div>
            </div>
        );
    };

    const renderChannelSection = () => {
        return (
            <div className={styles.eachSectionStyle}>
                <p className={styles.eachSectionTitleTextStyle}>Channels</p>
                <div className={styles.inputSectionStyle}>
                    <Input
                        name="website_url"
                        lableText={"Website"}
                        placeholder={"Enter website link"}
                        value={formik.values.website_url}
                        onChange={formik.handleChange}
                        onFocus={() => {
                            formik.setFieldError("website_url", "");
                            setErrorMessage("");
                        }}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.website_url &&
                            formik.errors.website_url
                                ? formik.errors.website_url
                                : ""
                        }
                    />
                    <Input
                        name="instagram_url"
                        lableText={"Instagram"}
                        placeholder={"Enter Instagram Id"}
                        value={formik.values.instagram_url}
                        onChange={formik.handleChange}
                        onFocus={() => {
                            formik.setFieldError("instagram_url", "");
                            setErrorMessage("");
                        }}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.instagram_url &&
                            formik.errors.instagram_url
                                ? formik.errors.instagram_url
                                : ""
                        }
                    />
                </div>
            </div>
        );
    };

    const renderPasswordSection = () => {
        return (
            <div className={styles.passwordSectionStyle}>
                <Input
                    name="password"
                    type="password"
                    lableText={"Password"}
                    placeholder={"Enter your password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onFocus={() => {
                        formik.setFieldError("password", "");
                        setErrorMessage("");
                    }}
                    onBlur={formik.handleBlur}
                    hasError={
                        formik.touched.password && formik.errors.password
                            ? formik.errors.password
                            : ""
                    }
                />
                <Input
                    name="repeat_password"
                    type="password"
                    lableText={"Repeat password"}
                    placeholder={"Repeat password"}
                    value={formik.values.repeat_password}
                    onFocus={() => {
                        formik.setFieldError("repeat_password", "");
                        setErrorMessage("");
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    hasError={
                        formik.touched.repeat_password &&
                        formik.errors.repeat_password
                            ? formik.errors.repeat_password
                            : ""
                    }
                />
            </div>
        );
    };

    const renderButtonSection = () => {
        return (
            <div className={styles.btnSectionStyle}>
                <Button
                    type="submit"
                    title={actionLoading ? "Loading..." : "Next"}
                    onClick={() => console.log("cc")}
                    btnStyle={styles.btnViewStyle}
                />
            </div>
        );
    };

    const renderFormSection = () => {
        return (
            <div className={styles.subContainerStyle}>
                <div className={styles.topSectionStyle}>
                    <h2 className={styles.registerTextStyle}>Register</h2>
                </div>
                <form
                    onSubmit={formik.handleSubmit}
                    className={styles.formStyle}>
                    <div className={styles.middelSectionStyle}>
                        {renderProfileSection()}
                        {renderContactSection()}
                        {renderChannelSection()}
                        {renderPasswordSection()}
                        {errorMessage && (
                            <p className={styles.errorMessageStyles}>
                                {errorMessage}
                            </p>
                        )}
                    </div>
                    {renderButtonSection()}
                </form>
            </div>
        );
    };

    const renderSignUpCompletedSection = () => {
        return (
            <div className={styles.signUpCompletedSectionStyle}>
                <p className={styles.registrationDescTextStyle}>
                    Thank you for register your store.
                </p>
                <Button
                    title={"Login"}
                    onClick={() => navigate("/login")}
                    btnStyle={styles.loginBtnStyle}
                />
            </div>
        );
    };
    const renderModalSection = () => {
        return (
            <Modal
                show={isSignUp}
                onClose={closeModal}
                showOverlay={true}
                customPopUpStyles={styles.registerModalStyles}
                // showCloseButton={true}
                popupRef={popupRef}
                handleClickOutside={handleClickOutsideStaticPopup}>
                <div className={styles.registerModalStyles}>
                    <h2 className={styles.messageHeaderStyles}>
                        Thankyou for registering your store in soopsystems
                    </h2>
                    <p className={styles.messageDescStyles}>
                        Here, we Inform that please wait your store registraton
                        request sent. it will take short time and we can get
                        email after accepting your store request. form the mail
                        we can login to your account.
                    </p>
                    <Button
                        title={"Back"}
                        onClick={() => {
                            navigate("/landing");
                            setIsSignUp(false);
                        }}
                        btnStyle={styles.backButtonStyles}
                    />
                </div>
            </Modal>
        );
    };

    return (
        <div className={styles.containerStyle}>
            {isSignUp ? renderModalSection() : renderFormSection()}
        </div>
    );
};
export default SignUp;
