import PropTypes from "prop-types";
import Input from "components/input";
import Button from "components/button";
import styles from "./styles.module.css";
const Popup = (props) => {
    const {
        show,
        title,
        addedProductDeatail,
        name,
        placeholder,
        lableText,
        closeHandler = () => {},
        addProductValue = () => {},
        onChange = () => {},
        value,
    } = props;

    return (
        <div
            style={{
                visibility: show ? "visible" : "hidden",
                opacity: show ? "1" : "0",
            }}
            className={styles.overlay}
        >
            <div className={styles.popup}>
                <h2 className={styles.addProductDetailsStyle}>
                    Add Custom {title}
                </h2>
                <span className={styles.close} onClick={closeHandler}>
                    &times;
                </span>
                <div className={styles.content}>
                    <div className={styles.addBrandContainer}>
                        <p>{addedProductDeatail}</p>
                        <Input
                            name={name}
                            placeholder={placeholder}
                            lableText={lableText}
                            value={value}
                            onChange={onChange}
                            lableTextStyle={styles.lableTextStyle}
                            customInputStyle={styles.customInputViewStyle}
                        />
                        <Button
                            title="Confirm"
                            onClick={addProductValue}
                            btnStyle={styles.btnViewStyle}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

Popup.propTypes = {
    title: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
export default Popup;
