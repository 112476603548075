import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import classNames from "classnames";

function Modal({
    show,
    onClose,
    children,
    showOverlay,
    customPopUpStyles,
    showCloseButton,
    popupRef,
    handleClickOutside,
}) {
    useEffect(() => {
        if (show && popupRef.current) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show, handleClickOutside, popupRef]);

    if (!show) return null;

    return (
        <div className={showOverlay ? styles.overlay : ""}>
            <div
                ref={popupRef}
                className={classNames(styles.popup, customPopUpStyles)}>
                {showCloseButton && (
                    <button className={styles.closeButton} onClick={onClose}>
                        &times;
                    </button>
                )}
                {children}
            </div>
        </div>
    );
}

Modal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    showOverlay: PropTypes.bool,
    showCloseButton: PropTypes.bool,
    popupRef: PropTypes.object.isRequired,
    handleClickOutside: PropTypes.func.isRequired,
};

export default Modal;
