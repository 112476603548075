import React from "react";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
const Landing = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.containerStyle}>
      <div className={styles.subContainerStyle}>
        <div
          onClick={() => navigate("/login")}
          className={styles.eachSectionStyle}
        >
          <p className={styles.textStyle}>Login</p>
        </div>
        <div
          onClick={() => navigate("/signup")}
          className={styles.eachSectionStyle}
        >
          <p className={styles.textStyle}>Register</p>
        </div>
      </div>
    </div>
  );
};
export default Landing;
