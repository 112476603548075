import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../components/input";
import Button from "../../components/button";
import { useAuthContext } from "hooks/useAuthContext";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { resetPassWordApi } from "networking/apis/store/resetpassword";
import { useToast } from "providers/ToastProvider";
import { useSpinner } from "providers/SpinnerProvider";

const ResetPassword = () => {
    /*contexts*/
    const { showToast } = useToast();
    const { setIsLoading } = useSpinner();
    /*navigation*/
    const navigate = useNavigate();
    /* Context */
    const { setErrorMessage, errorMessage } = useAuthContext();

    /* States */
    const [actionLoading, setActionLoading] = useState(false);
    const [resetToken, setResetToken] = useState(
        window.location.pathname.split("/")[2]
    );

    /* ResetPassword validation schema */
    const ResetPassWordSchema = Yup.object().shape({
        new_password: Yup.string().required("Password is required"),
        confirm_password: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
    });

    /* Handle password reset */
    const handleResetPassWord = async (values, { resetForm }) => {
        setIsLoading(true);
        try {
            const data = {
                ...values,
                token: resetToken,
            };
            const resetPassWordResponse = await resetPassWordApi(data);

            if (resetPassWordResponse.data.type === "success") {
                console.log("values", resetPassWordResponse);
                setIsLoading(false);
                showToast.success(resetPassWordResponse.data.message);
                resetForm();
                navigate("/login");
            } else if (
                resetPassWordResponse.data.type === "fail" &&
                resetPassWordResponse.status === 400
            ) {
                setIsLoading(false);
                showToast.error(resetPassWordResponse.data.message);
            } else {
                setIsLoading(false);
                showToast.error(resetPassWordResponse.data.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };

    /* Initialization of formik values */
    const formik = useFormik({
        initialValues: {
            new_password: "",
            confirm_password: "",
        },
        validationSchema: ResetPassWordSchema,
        onSubmit: handleResetPassWord,
    });

    const renderInputSection = () => {
        return (
            <div className={styles.inputSectionStyle}>
                <p className={styles.titleTextStyle}>Reset Password</p>
                <div className={styles.inputSubSectionStyle}>
                    <div className={styles.PasswordInputStyle}>
                        <Input
                            name="new_password"
                            type="password"
                            label="Enter Password"
                            placeholder="Enter New Password"
                            value={formik.values.new_password}
                            onChange={formik.handleChange}
                            onFocus={() => {
                                formik.setFieldError("new_password", "");
                                setErrorMessage("");
                            }}
                            onBlur={formik.handleBlur}
                            hasError={
                                formik.touched.new_password &&
                                formik.errors.new_password
                                    ? formik.errors.new_password
                                    : ""
                            }
                        />
                        <Input
                            name="confirm_password"
                            type="password"
                            label="Confirm Password"
                            placeholder="Enter Confirm Password"
                            value={formik.values.confirm_password}
                            onChange={formik.handleChange}
                            onFocus={() => {
                                formik.setFieldError("confirm_password", "");
                                setErrorMessage("");
                            }}
                            onBlur={formik.handleBlur}
                            hasError={
                                formik.touched.confirm_password &&
                                formik.errors.confirm_password
                                    ? formik.errors.confirm_password
                                    : ""
                            }
                        />
                    </div>
                    {errorMessage && (
                        <p className={styles.errorMessageStyles}>
                            {errorMessage}
                        </p>
                    )}
                </div>
            </div>
        );
    };

    const renderButtonSection = () => {
        return (
            <div className={styles.btnSectionStyle}>
                <Button
                    type="submit"
                    title={actionLoading ? "Loading..." : "Submit"}
                    btnStyle={styles.btnViewStyle}
                />
            </div>
        );
    };

    return (
        <div className={styles.containerStyle}>
            <form
                onSubmit={formik.handleSubmit}
                className={styles.subContainerStyle}>
                {renderInputSection()}
                {renderButtonSection()}
            </form>
        </div>
    );
};

export default ResetPassword;
