import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuthContext } from "../hooks/useAuthContext";

const GuestGuard = (props) => {
    const { children } = props;
    const { isInitialized, isLoggedIn, userType } = useAuthContext();

    let dashBoardRoute = "/products";

    if (!isInitialized) {
        return "Loading...";
    }
    if (isLoggedIn && userType === "store") {
        return <Navigate to={dashBoardRoute} />;
    }
    if (isLoggedIn && userType === "admin") {
        return <Navigate to={"/adminpanel"} />;
    }
    return <>{children}</>;
};

GuestGuard.propTypes = {
    children: PropTypes.node,
};

export default GuestGuard;
