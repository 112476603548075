import React, { useContext } from "react";
import { CartDataContext } from "providers/cartdataprovider";

export const useCartDataContext = () => {
    const context = useContext(CartDataContext);
    if (!context) {
        throw new Error("useCartData provider inside the CartData provider");
    }
    return context;
};
