import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import { AuthProvider } from "providers/authprovider";
// import { AppDataProvider } from "providers/appdataprovider";
import { CartDataProvider } from "providers/cartdataprovider";
import { SellerDataProvider } from "providers/sellerdataprovider";
import { ToastProvider } from "providers/ToastProvider";
import SpinnerProvider from "providers/SpinnerProvider";
import { NetWorkProvider } from "providers/NetWorkProvider";

function App() {
    return (
        <BrowserRouter>
            <ToastProvider>
                <NetWorkProvider>
                    <SpinnerProvider>
                        <AuthProvider>
                            {/* <AppDataProvider> */}
                            <SellerDataProvider>
                                <CartDataProvider>
                                    <AppRoutes />
                                </CartDataProvider>
                            </SellerDataProvider>
                            {/* </AppDataProvider> */}
                        </AuthProvider>
                    </SpinnerProvider>
                </NetWorkProvider>
            </ToastProvider>
        </BrowserRouter>
    );
}

export default App;
