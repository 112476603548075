import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import Button from "components/button";
import { useLocation, useNavigate } from "react-router-dom";
import { personIcon } from "resources/images/images";
import TextArea from "components/textarea";
import { ProductsData } from "resources/dummyData";
import { useAuthContext } from "hooks/useAuthContext";
import { getSellerDetailsApi } from "networking/apis/seller/getsellerdetails";
import { addProductToCartApi } from "networking/apis/cart/addproducttocart";
import { useCartDataContext } from "hooks/useCartDataContext";
import { useToast } from "providers/ToastProvider";
import { useSpinner } from "providers/SpinnerProvider";
import { strings } from "resources/strings/english-string";
import { deleteSellerApi } from "networking/apis/seller/deleteseller";
const Profile = () => {
    /*contexts*/
    const { storeData } = useAuthContext();
    const { setIsLoading } = useSpinner();
    const { handleGetCartProducts, update } = useCartDataContext();
    const { showToast } = useToast();

    /*states*/
    const [sellerAndProductDetails, setSellerAndProductDetails] = useState();
    // console.log(sellerAndProductDetails);

    /*strings*/
    const { sellerStrings, productStrings } = strings;

    /*routing*/
    const navigate = useNavigate();
    const location = useLocation();
    const sellerDetails = location.state; /*get seller details*/

    /*onload the page get seller and product details*/
    useEffect(() => {
        handleGetSellerDeatailsAndProducts();
    }, [update]);

    /*handle get all seller details && products*/

    const handleGetSellerDeatailsAndProducts = async () => {
        setIsLoading(true);
        try {
            const getSellerDetailsAndProductsResponse =
                await getSellerDetailsApi(sellerDetails?._id);
            if ((getSellerDetailsAndProductsResponse.data.type = "success")) {
                setIsLoading(false);
                setSellerAndProductDetails(
                    getSellerDetailsAndProductsResponse.data.data[0]
                );
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };

    /*add product to the cart*/
    const handleAddProductToCart = async (item) => {
        setIsLoading(true);
        try {
            let addProductToCartData = {
                store_id: sellerAndProductDetails.store_id,
                product: item._id,
            };
            // console.log("addProductToCartData", addProductToCartData);

            const addProductToCartResponse = await addProductToCartApi(
                addProductToCartData
            );
            if (addProductToCartResponse.data.type === "success") {
                console.log(addProductToCartResponse);
                setIsLoading(false);
                handleGetCartProducts();
                showToast.success(addProductToCartResponse.data.message);
            } else {
                console.log(addProductToCartResponse);
                setIsLoading(false);
                showToast.error(addProductToCartResponse.data.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };

    /*handle delete seller*/

    const handleDeleteSeller = async () => {
        setIsLoading(true);
        try {
            const deleteSellerResponse = await deleteSellerApi(
                sellerDetails?._id
            );
            if (deleteSellerResponse.data.type === "success") {
                // console.log("deleteSellerResponse", deleteSellerResponse);
                setIsLoading(false);
                showToast.success(deleteSellerResponse.data.message);
                handleGetSellerDeatailsAndProducts();
                navigate("/sellers");
            } else {
                showToast.error(deleteSellerResponse.data.message);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };

    /*caluculation of consigned price*/

    const calculateConsignedPrice = (item) => {
        const price = parseFloat(item.price.replace(/,/g, ""));
        if (isNaN(price)) {
            return "N/A";
        }

        if (item.consignment === "Consignment deal 50%") {
            const consignedPrice = (price * 50) / 100;
            return consignedPrice ? consignedPrice : "N/A";
        } else if (item.consignment === "Resale") {
            const consignedPrice = price;
            return consignedPrice ? consignedPrice : "N/A";
        } else if (item.consignment === "Retail") {
            const consignedPrice = 0;
            return consignedPrice;
        } else {
            const consignedPrice = (price * parseFloat(item.consignment)) / 100;
            return consignedPrice ? consignedPrice : "N/A";
        }
    };

    /*format the price with comma(,)*/

    const formatPriceWithCommas = (consignment) => {
        return consignment.toLocaleString("en-US");
    };

    const renderTopSection = () => {
        return (
            <div className={styles.topSectionStyle}>
                <p className={styles.titleTextStyle}>Profile</p>
                <div className={styles.backBtnViewStyle}>
                    <Button
                        title="Back"
                        onClick={() => navigate(-1)}
                        btnStyle={styles.backBtnStyle}
                    />
                </div>
            </div>
        );
    };

    const renderProfileDetailsLeftSection = () => {
        return (
            <div className={styles.profileDetailsSectionLeftStyle}>
                <div>
                    <p className={styles.lableTextStyle}>
                        {sellerStrings.sellerName}
                    </p>
                    <p className={styles.textStyle}>
                        {sellerDetails?.seller_name}
                    </p>
                </div>
                <div>
                    <p className={styles.lableTextStyle}>
                        {sellerStrings.sellerPhone}
                    </p>
                    <p className={styles.textStyle}>
                        {sellerDetails?.phone_number}
                    </p>
                </div>
                <div>
                    <p className={styles.lableTextStyle}>
                        {sellerStrings.sellerEmail}
                    </p>
                    <p className={styles.textStyle}>{sellerDetails?.email}</p>
                </div>
            </div>
        );
    };

    const renderProfileDetailsRightSection = () => {
        return (
            <div className={styles.profileDetailsSectionLeftStyle}>
                <div>
                    <p className={styles.lableTextStyle}>
                        {sellerStrings.sellerMemberSince}
                    </p>
                    <p className={styles.textStyle}>22.03.2023</p>
                </div>
                <div>
                    <p className={styles.lableTextStyle}>
                        {sellerStrings.sellerRegisterNo}
                    </p>
                    <p className={styles.textStyle}>
                        {sellerDetails?.reg_number}
                    </p>
                </div>
                <div>
                    <p className={styles.lableTextStyle}>
                        {sellerStrings.sellerBankNo}
                    </p>
                    <p className={styles.textStyle}>
                        {sellerDetails?.bank_account}
                    </p>
                </div>
            </div>
        );
    };

    const renderProfileTopSection = () => {
        return (
            <div className={styles.profileTopSectionStyle}>
                <div className={styles.profileTopSectionLeftStyle}>
                    <Button
                        title={sellerDetails?.seller_uid}
                        disabled={true}
                        icon={personIcon}
                        customImgStyle={styles.profileImgStyle}
                        btnStyle={styles.profileIdSectionStyle}
                    />
                    <div className={styles.profileDetailsSectionStyle}>
                        {renderProfileDetailsLeftSection()}
                        {renderProfileDetailsRightSection()}
                    </div>
                </div>
                <div className={styles.profileTopSectionRightStyle}>
                    <div className={styles.commentSectionStyle}>
                        <p className={styles.lableTextStyle}>
                            {sellerStrings.sellerComment}
                        </p>
                        <TextArea
                            disabled={true}
                            customInputStyle={styles.commentViewStyle}
                            value={sellerDetails?.comment}
                        />
                    </div>
                    <div className={styles.editAndDeleteButtonBlock}>
                        <div>
                            <Button
                                title="Edit"
                                onClick={() => {
                                    navigate("/addSeller", {
                                        state: sellerAndProductDetails,
                                    });
                                }}
                                btnStyle={styles.editBtnStyle}
                            />
                        </div>
                        <div
                            className={styles.deleteBtnStyle}
                            onClick={handleDeleteSeller}>
                            <p className={styles.deleteSellerText}>
                                Delete &nbsp;Seller
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderTableTopSection = () => {
        return (
            <table>
                <thead>
                    <tr className={styles.headingStyle}>
                        <td className={styles.headingStyle2}>Product ID</td>
                        <td className={styles.headingStyle2}>
                            {productStrings.brand}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.category}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.material}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.color}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.price}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.consignmentPercent}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.consignedPrice}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.status}
                        </td>
                        <td className={styles.headingStyle2}>
                            {productStrings.createdDate}
                        </td>
                        <td className={styles.headingStyle2}></td>
                        <td className={styles.headingStyle2}></td>
                    </tr>
                </thead>
            </table>
        );
    };
    const renderTableBottomSection = () => {
        return (
            <table>
                <tbody>
                    {sellerAndProductDetails?.products &&
                        sellerAndProductDetails?.products?.map(
                            (item, index) => (
                                <tr
                                    key={index}
                                    className={styles.assetListDataRowStyle}>
                                    <td className={styles.serialNoStyle}>
                                        <p
                                            className={[
                                                styles.serialNoTextStyle,
                                                sellerAndProductDetails.length ===
                                                    1 &&
                                                    styles.serialNoTextStyle2,
                                            ].join(" ")}>
                                            {item.product_uid}
                                        </p>
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        {/* {item.brand} */}
                                        {item.brand ? item.brand : "N/A"}
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        {item.category ? item.category : "N/A"}
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        {item.material ? item.material : "N/A"}
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        {item.colour ? item.colour : "N/A"}
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        {item.price ? item.price : "N/A"}
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        {item.consignment
                                            ? item.consignment
                                            : "N/A"}
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        {formatPriceWithCommas(
                                            calculateConsignedPrice(item)
                                        )}
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        <p
                                            className={
                                                item.availability_status ===
                                                "InStore"
                                                    ? styles.statusInStoreViewStyle
                                                    : item.availability_status ===
                                                      "Sold"
                                                    ? styles.statusSoldViewStyle
                                                    : item.availability_status ===
                                                      "Paid Out"
                                                    ? styles.statusPaidOutViewStyle
                                                    : item.availability_status ===
                                                      "Packed"
                                                    ? styles.statusPackedViewStyle
                                                    : ""
                                            }>
                                            {item.availability_status}
                                        </p>
                                    </td>

                                    <td className={styles.createdAtTextStyle}>
                                        {new Date(
                                            item.created_at
                                        ).toLocaleDateString("pt-PT")}
                                    </td>
                                    <td
                                        className={[
                                            styles.editBtnViewStyle,
                                            item.status === "Sold" &&
                                                styles.disableBtnViewStyle,
                                        ].join(" ")}>
                                        <div
                                            onClick={() => {
                                                tapOnEditProduct(item);
                                            }}>
                                            <p>Edit</p>
                                        </div>
                                    </td>
                                    {/* <td
                                        className={[
                                            styles.addBtnViewStyle,
                                            !item.availability_status &&
                                                styles.disableBtnViewStyle,
                                        ].join(" ")}
                                    >
                                        <div
                                            onClick={
                                                item.availability_status
                                                    ? () => {
                                                          handleAddProductToCart(
                                                              item._id
                                                          );
                                                      }
                                                    : () => {}
                                            }
                                        >
                                            <p>Add to cart</p>
                                        </div>
                                    </td> */}
                                    <td
                                        className={[
                                            styles.addBtnViewStyle,
                                            [
                                                "Sold",
                                                "Paid Out",
                                                "Packed",
                                            ].includes(
                                                item.availability_status
                                            ) && styles.disableBtnViewStyle,
                                        ].join(" ")}>
                                        <div
                                            onClick={
                                                ![
                                                    "Sold",
                                                    "Paid Out",
                                                    "Packed",
                                                ].includes(
                                                    item.availability_status
                                                )
                                                    ? () => {
                                                          handleAddProductToCart(
                                                              item
                                                          );
                                                      }
                                                    : () => {}
                                            }>
                                            <p>{productStrings.addToCart}</p>
                                        </div>
                                    </td>
                                </tr>
                            )
                        )}
                </tbody>
            </table>
        );
    };

    /*send seller details to add product page to display seller details input section*/

    const tapOnEditProduct = (selectedProduct) => {
        let sellerData = {
            seller_uid: sellerAndProductDetails.seller_uid,
            seller_name: sellerAndProductDetails.seller_name,
        };
        selectedProduct.seller_id = sellerData;
        console.log(selectedProduct);
        navigate("/addProduct", {
            state: { data: selectedProduct, type: "edit" },
        });
    };

    const renderProfileBottomSection = () => {
        return (
            <div className={styles.profileBottomSectionStyle}>
                {renderTableTopSection()}
                <div className={styles.profileBottomSubSectionStyle}>
                    {renderTableBottomSection()}
                </div>
            </div>
        );
    };
    const renderProfileSection = () => {
        return (
            <div className={styles.profileSectionStyle}>
                <div className={styles.profileSubSectionStyle}>
                    {renderProfileTopSection()}
                    {renderProfileBottomSection()}
                </div>
            </div>
        );
    };
    return (
        <div className={styles.containerStyle}>
            {renderTopSection()}
            {renderProfileSection()}
        </div>
    );
};
export default Profile;
