import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthGuard from "../guards/authguard";
import GuestGuard from "../guards/guestguard";
import DashboardLayout from "../layouts/dashboard";
import Login from "../pages/login";
import Products from "../pages/products";
import Sellers from "pages/sellers";
import SignUp from "../pages/signup";
import Reports from "pages/reports";
import AddProduct from "pages/addproduct";
import AddSeller from "pages/addseller";
import Profile from "pages/profile";
import Landing from "pages/landing";
import NotFoundPage from "pages/notfound";
import ForGotPassWord from "pages/forgotpassword";
import ResetPassword from "pages/resetpassword";
import VerifyStore from "pages/verify";
import AdminPanel from "pages/admin/adminpanel";
import AdminLogin from "pages/admin/login";
import { useAuthContext } from "hooks/useAuthContext";

const AppRoutes = () => {
    const { userType } = useAuthContext();
    return useRoutes([
        {
            path: "",
            children: [
                {
                    element: (
                        <AuthGuard>
                            {userType === "store" ? (
                                <DashboardLayout />
                            ) : (
                                <NotFoundPage />
                            )}
                        </AuthGuard>
                    ),
                    children: [
                        {
                            element: <Navigate to={"/products"} replace />,
                            index: true,
                        },
                        { path: "/products", element: <Products /> },
                        { path: "/sellers", element: <Sellers /> },
                        { path: "/reports", element: <Reports /> },
                        { path: "/addProduct", element: <AddProduct /> },
                        { path: "/addSeller", element: <AddSeller /> },
                        { path: "/profile", element: <Profile /> },
                        {
                            path: "*",
                            element: <NotFoundPage />,
                        },
                    ],
                },
                {
                    path: "/adminpanel",
                    element: (
                        <AuthGuard>
                            {userType === "admin" ? (
                                <AdminPanel />
                            ) : (
                                <NotFoundPage />
                            )}
                        </AuthGuard>
                    ),
                },

                {
                    path: "/landing",
                    element: (
                        <GuestGuard>
                            <Landing />
                        </GuestGuard>
                    ),
                },
                {
                    path: "signup",
                    element: (
                        <GuestGuard>
                            <SignUp />
                        </GuestGuard>
                    ),
                },
                {
                    path: "login",
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    ),
                },
                {
                    path: "adminlogin",
                    element: (
                        <GuestGuard>
                            <AdminLogin />
                        </GuestGuard>
                    ),
                },
                {
                    path: "/verify/:verify_token",
                    element: <VerifyStore />,
                },
                {
                    path: "/forgotpassword",
                    element: <ForGotPassWord />,
                },
                {
                    path: "/resetpassword/:token",
                    element: <ResetPassword />,
                },
                {
                    path: "*",
                    element: <NotFoundPage />,
                },
            ],
        },
    ]);
};
export default AppRoutes;
