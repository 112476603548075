import React, { useEffect, useState } from "react";
import Button from "components/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/input";
import { personIcon, plus, search } from "resources/images/images";
import TextArea from "components/textarea";
import styles from "./style.module.css";
import { addSellerApi } from "networking/apis/seller/addseller";
import { useAuthContext } from "hooks/useAuthContext";
import { editSellerApi } from "networking/apis/seller/editseller";
import { useToast } from "providers/ToastProvider";
import { useSpinner } from "providers/SpinnerProvider";
const AddSeller = () => {
    /*contexts*/

    const { storeData, isSellerEdit } = useAuthContext();
    const { setIsLoading } = useSpinner();
    const { showToast } = useToast();

    /*routing*/

    const navigate = useNavigate();
    const location = useLocation();
    const sellerAndProductDetails = location.state;

    /*states*/
    const [actionLoading, setActionLoading] = useState(false);
    const [editMessage, setEditMessage] = useState();
    const [addSeller, setAddSeller] = useState();

    /*formik validation schemas*/

    const saveSellerSchema = Yup.object().shape({
        seller_name: Yup.string().required("seller name is required"),
        seller_uid: Yup.string(),
        phone_number: Yup.string()
            .required("Phone number is required")
            .matches(/^[0-9]+$/, "Phone number must contain only digits"),
        email: Yup.string()
            .email("Enter a valid email address")
            .required("Email is required"),

        bank_account: Yup.string()
            .required()
            .matches(/^[0-9]+$/, "Bank account must contain digits"),
        reg_number: Yup.string()
            .required()
            .matches(/^[0-9]+$/, "Register number must contain digits"),
        comment: Yup.string(),
    });

    /*handle add seller*/

    const handleSaveSeller = async (values, { resetForm }) => {
        setIsLoading(true);
        setActionLoading(true);
        try {
            let addSellerData = { ...values };
            // delete addSellerData.seller_uid;
            Object.assign(addSellerData, {
                store_id: storeData.store._id,
            });

            const addSellerResponse = await addSellerApi(addSellerData);
            if (addSellerResponse.data.type === "success") {
                setIsLoading(false);
                showToast.success(addSellerResponse.data.message);
                setActionLoading(false);
                navigate(-1);
            } else {
                setIsLoading(false);
                showToast.error(addSellerResponse.data.message);
            }
        } catch (error) {
            setIsLoading(false);
            setActionLoading(false);
        }
    };

    /*handle  edit seller*/

    const handleEditSeller = async (values) => {
        try {
            let editData = { ...values };
            Object.assign(editData, {
                seller_id: sellerAndProductDetails?._id,
                store_id: sellerAndProductDetails?.store_id,
            });

            const editSellerResponse = await editSellerApi(editData);
            if (editSellerResponse.data.type === "success") {
                showToast.success(editSellerResponse.data.message);
                navigate("/profile", { state: editSellerResponse.data.data });
            } else {
                showToast.error(
                    "error in saving",
                    editSellerResponse.data.message
                );
            }
        } catch (error) {
            console.log("error in edit seller response", error);
        }
    };

    /*initialization of formik values*/

    const formik = useFormik({
        initialValues: {
            seller_name: sellerAndProductDetails?.seller_name || "",
            seller_uid: sellerAndProductDetails?.seller_uid || "",
            phone_number: sellerAndProductDetails?.phone_number || "",
            email: sellerAndProductDetails?.email || "",
            bank_account: sellerAndProductDetails?.bank_account || "",
            reg_number: sellerAndProductDetails?.reg_number || "",
            comment: sellerAndProductDetails?.comment || "",
        },
        validationSchema: saveSellerSchema,
        onSubmit: sellerAndProductDetails ? handleEditSeller : handleSaveSeller,
        enableReinitialize: true,
    });

    /*Edit & add new seller title section*/

    const renderLeftSection = () => {
        return (
            <p className={styles.titleTextStyle}>
                {sellerAndProductDetails ? "Edit seller" : "Add new seller"}
            </p>
        );
    };

    // Search Seller sections
    const renderSellerSctions = () => {
        return (
            <div className={styles.sellerSectionViewStyle}>
                <div className={styles.sellerSubViewStyle}>
                    <div className={styles.eachSectionViewStyle}>
                        <Input
                            name="seller_name"
                            placeholder="Enter name..."
                            lableText="Seller name * (First and Last name)"
                            value={formik.values.seller_name}
                            onChange={formik.handleChange}
                            onFocus={() =>
                                formik.setFieldError("seller_name", "")
                            }
                            onBlur={formik.handleBlur}
                            hasError={
                                formik.touched.seller_name &&
                                formik.errors.seller_name
                                    ? formik.errors.seller_name
                                    : ""
                            }
                            lableTextStyle={styles.lableTextStyle}
                            customInputStyle={styles.customInputViewStyle}
                        />
                    </div>
                    <div className={styles.eachSectionViewStyle}>
                        <Input
                            name="seller_uid"
                            placeholder="id ..."
                            value={formik.values.seller_uid}
                            onChange={formik.handleChange}
                            onFocus={() =>
                                formik.setFieldError("seller_uid", "")
                            }
                            onBlur={formik.handleBlur}
                            hasError={
                                formik.touched.seller_uid &&
                                formik.errors.seller_uid
                                    ? formik.errors.seller_uid
                                    : ""
                            }
                            lableTextStyle={styles.lableTextStyle}
                            customInputStyle={styles.sellerIdViewStyle}
                            customInputViewStyle={styles.customInputViewStyle2}
                            customLeftIconViewStyle={
                                styles.customLeftIconViewStyle
                            }
                            leftIcon={personIcon}
                            customErrorTextStyle={styles.customErrorTextStyle}
                            disabled={sellerAndProductDetails}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderPhoneAndEmailSection = () => {
        return (
            <div className={styles.sectionViewStyle}>
                <div className={styles.phoneAndEmailSectionViewStyle}>
                    <Input
                        name="phone_number"
                        placeholder="Enter phone number..."
                        lableText="Phone *"
                        value={formik.values.phone_number}
                        onChange={formik.handleChange}
                        onFocus={() => formik.setFieldError("phone_number", "")}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.phone_number &&
                            formik.errors.phone_number
                                ? formik.errors.phone_number
                                : ""
                        }
                        lableTextStyle={styles.lableTextStyle}
                        customInputStyle={styles.customInputViewStyle}
                    />

                    <Input
                        name="email"
                        placeholder="Enter email..."
                        lableText="E-mail*"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onFocus={() => formik.setFieldError("email", "")}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.email && formik.errors.email
                                ? formik.errors.email
                                : ""
                        }
                        lableTextStyle={styles.lableTextStyle}
                        customInputStyle={styles.customInputViewStyle}
                    />
                </div>
            </div>
        );
    };

    const renderOtherSections = () => {
        return (
            <div className={styles.sectionViewStyle}>
                <div className={styles.otherLeftSectionStyle}>
                    <Input
                        name="bank_account"
                        placeholder="Enter bank..."
                        lableText="Bank*"
                        value={formik.values.bank_account}
                        onChange={formik.handleChange}
                        onFocus={() => formik.setFieldError("bank_account", "")}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.bank_account &&
                            formik.errors.bank_account
                                ? formik.errors.bank_account
                                : ""
                        }
                        lableTextStyle={styles.lableTextStyle}
                        customInputStyle={styles.customInputViewStyle}
                    />

                    <Input
                        name="reg_number"
                        placeholder="Enter Reg number..."
                        lableText="Reg number*"
                        value={formik.values.reg_number}
                        onChange={formik.handleChange}
                        onFocus={() => formik.setFieldError("reg_number", "")}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.reg_number &&
                            formik.errors.reg_number
                                ? formik.errors.reg_number
                                : ""
                        }
                        lableTextStyle={styles.lableTextStyle}
                        customInputStyle={styles.customInputViewStyle}
                    />
                </div>
                <div className={styles.eachSectionViewStyle}>
                    <TextArea
                        name="comment"
                        placeholder="Enter comment..."
                        lableText="Comment"
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.comment && formik.errors.comment
                                ? formik.errors.comment
                                : ""
                        }
                        lableTextStyle={styles.commentlableTextStyle}
                        customInputStyle={styles.commentInputViewStyle}
                        customErrorTextStyle={styles.customErrorTextStyle}
                    />
                </div>
            </div>
        );
    };

    const renderBtnSection = () => {
        return (
            <div className={styles.saveBtnSectionViewStyles}>
                <Button
                    type="submit"
                    title={
                        actionLoading
                            ? "Loading ...."
                            : "Save seller" && sellerAndProductDetails
                            ? "Edit seller"
                            : "Save seller"
                    }
                    btnStyle={styles.saveBtnStyle}
                />
            </div>
        );
    };
    const renderFormSection = () => {
        return (
            <form
                onSubmit={formik.handleSubmit}
                className={styles.formViewStyle}
            >
                {renderSellerSctions()}
                {renderPhoneAndEmailSection()}
                {renderOtherSections()}
                {renderBtnSection()}
            </form>
        );
    };
    return (
        <div className={styles.containerStyle}>
            <div className={styles.leftSectionStyle}>
                {renderLeftSection()}
                {renderFormSection()}
            </div>
            <div className={styles.rightSectionStyle}>
                <Button
                    title="Back"
                    onClick={() => navigate(-1)}
                    btnStyle={styles.customBtnStyle}
                />
            </div>
        </div>
    );
};
export default AddSeller;
