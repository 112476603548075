import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { closeIcon } from "resources/images/images";
import Button from "components/button";
import TextArea from "components/textarea";
import styles from "./style.module.css";
import { useCartDataContext } from "hooks/useCartDataContext";
import { purchaseProductApi } from "networking/apis/products/purchasedproduct";
import { useToast } from "providers/ToastProvider";
import { AiOutlineDelete } from "react-icons/ai";
import { removeProductFromCartApi } from "networking/apis/cart/removeproductfromcart";
import { useAuthContext } from "hooks/useAuthContext";
import { useSpinner } from "providers/SpinnerProvider";
const Cart = (props) => {
    const { onClose = () => {} } = props;

    /*contexts*/

    const { storeData } = useAuthContext();
    const { cartData, handleGetCartProducts, update, setUpdate } =
        useCartDataContext();
    const { showToast } = useToast();
    const { setIsLoading } = useSpinner();

    /*states*/

    const [purchaseMethod, setPurchaseMethod] = useState();
    const [isClicked, setIsClicked] = useState();
    const [errorMessage, setErrorMessage] = useState();

    /* get cart products data while cart open*/

    useEffect(() => {
        handleGetCartProducts();
    }, []);

    /*payment methods data*/

    const paymentMethods = [
        { title: "Cash", method: "Cash" },
        { title: "MobilePay", method: "MobilePay" },
        { title: "Card", method: "Card" },
        { title: "Other", method: "Other" },
    ];

    /*handle purchase method button action*/

    const handleButtonClick = (method) => {
        if (purchaseMethod) {
            setPurchaseMethod();
            setErrorMessage("");
        } else {
            setPurchaseMethod(method);
            setErrorMessage("");
        }
    };

    const handleClick = () => {
        setIsClicked(true);
    };

    /*caluculate the total price:*/

    const caluculateTotalPrice = (cartData) => {
        return cartData.reduce(
            (total, item) =>
                total + parseFloat(item.product.price.replace(/,/g, "")),
            0
        );
    };

    const formatPriceWithCommas = (price) => {
        return price.toLocaleString("en-US");
    };

    /*validation while performing purchase products*/

    const validation = () => {
        if (purchaseMethod) {
            handlePurchaseProducts();
        } else {
            setErrorMessage("Please select purchase method");
        }
    };

    /*handle purchase product */

    const handlePurchaseProducts = async () => {
        setIsLoading(true);
        try {
            let cartProductIds = cartData?.map((item) => item.product._id);
            let purchaseMethodData = purchaseMethod;
            const requestData = {
                purchasedProducts: cartProductIds,
                payment_method: purchaseMethodData,
            };
            const getPurchaseProductResponse = await purchaseProductApi(
                requestData
            );
            if (getPurchaseProductResponse.data.type === "success") {
                setIsLoading(false);
                handleGetCartProducts();
                setUpdate(!update);
                showToast.success("products purchased");
            } else {
                setIsLoading(false);
                showToast.error("error in products purchased");
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };

    /*handle delete cart product*/

    const handleDeleteProduct = async () => {
        setIsLoading(true);
        try {
            const deleteProductResponse = await removeProductFromCartApi({
                store_id: storeData.store._id,
                product: cartData[0].product._id,
            });
            if (deleteProductResponse.data.type === "success") {
                setIsLoading(false);
                setUpdate(!update);
                showToast.success(deleteProductResponse.data.message);
                handleGetCartProducts();
            } else {
                setIsLoading(false);
                showToast.error(deleteProductResponse.data.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };

    const renderTopSection = () => {
        return (
            <div className={styles.cartHeaserStyle}>
                <p className={styles.titleTextStyle}>Shopping cart</p>
                <div className={styles.closeImgViewStyle}>
                    <img
                        src={closeIcon}
                        className={styles.closeImgStyle}
                        onClick={onClose}
                    />
                </div>
            </div>
        );
    };

    const renderMiddleSection = () => {
        return <div className={styles.middleSectionStyle}></div>;
    };

    const renderEmptySection = () => {
        return (
            <div className={styles.emptySectionStyle}>
                <p className={styles.emptyTextStyle}>No Data Available</p>
            </div>
        );
    };

    const renderCartProductsSection = () => {
        return (
            <div className={styles.cartProductsContainerStyle}>
                <div className={styles.cartProductDetailsMainBlockStyle}>
                    {renderCartProductDetailsSection()}

                    {renderTotalPriceRightBlockSection()}
                </div>

                {renderPaymentAndPurchaseBtnSection()}
            </div>
        );
    };

    const renderCartProductDetailsSection = () => {
        return (
            <div className={styles.cartProductDetailsBlockStyle}>
                {cartData &&
                    cartData.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={
                                    styles.cartProductsDetailsSubBlockStyle
                                }
                            >
                                <div
                                    className={
                                        styles.cartProductDescLeftBlockStyle
                                    }
                                >
                                    <div
                                        className={styles.cartProductDateStyle}
                                    >
                                        <p
                                            className={
                                                styles.cartProductDateDescStyle
                                            }
                                        >
                                            {/* Date: 23.06.2023 */}
                                            Date:
                                            {new Date(
                                                item.product.created_at
                                            ).toLocaleDateString("pt-PT")}
                                        </p>
                                        <span
                                            className={styles.deleteIconStyle}
                                            onClick={() =>
                                                handleDeleteProduct()
                                            }
                                        >
                                            {/* <AiOutlineDelete /> */}
                                            Delete
                                        </span>
                                    </div>

                                    <div
                                        className={
                                            styles.cartProductsDetailsBlockStyle
                                        }
                                    >
                                        <div
                                            className={
                                                styles.cartProductsDetailsStyle
                                            }
                                        >
                                            <p
                                                className={
                                                    styles.cartProductSerialNumberStyle
                                                }
                                            >
                                                {index + 1}.
                                            </p>

                                            <p
                                                className={
                                                    styles.cartProductDescriptionStyle
                                                }
                                            >
                                                {item.product.brand};
                                                {item.product.category},
                                                {item.product.model},
                                                {item.product.material},
                                                {item.product.colour},
                                                {item.product.size}
                                            </p>
                                        </div>
                                        <p className={styles.cartProductPrice}>
                                            {item.product.price}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    };

    const renderTotalPriceRightBlockSection = () => {
        return (
            <div className={styles.totalPriceRightBlockStyle}>
                <p className={styles.sumTextStyle}>
                    Sum
                    <span className={styles.sumPriceStyle}>
                        {formatPriceWithCommas(caluculateTotalPrice(cartData))}
                    </span>
                    kk
                </p>
            </div>
        );
    };

    const renderPaymentAndPurchaseBtnSection = () => {
        return (
            <div className={styles.paymentAndPurchaseBtnStyle}>
                <div className={styles.cartPaymentBlockStyle}>
                    <div className={styles.paymentButtonsStyle}>
                        {paymentMethods.map((methodInfo) => (
                            <Button
                                key={methodInfo.method}
                                title={methodInfo.title}
                                onClick={() =>
                                    handleButtonClick(methodInfo.method)
                                }
                                btnStyle={
                                    purchaseMethod === methodInfo.method
                                        ? styles.activeBtnStyle
                                        : styles.inActiveBtnStyle
                                }
                            />
                        ))}
                    </div>
                    <div className={styles.commentInputStyle}>
                        <TextArea
                            // value={comment}
                            placeholder="Comment"
                            onClick={() => console.log("Comment")}
                            // disabled={true}
                            customInputStyle={styles.commentViewStyle}
                        />
                    </div>
                </div>

                <div className={styles.confirmPurchaseBlockStyle}>
                    {<p className={styles.errorMsgStyle}>{errorMessage}</p>}
                    <Button
                        title="Confirm Purchase"
                        // icon={plus}
                        btnStyle={
                            isClicked
                                ? styles.confirmPurchaseBtnStyle
                                : styles.inActiveConfirmPurchaseBtnStyle
                        }
                        onClick={validation}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className={styles.cartViewStyle}>
            {renderTopSection()}
            {renderMiddleSection()}
            {cartData.length > 0
                ? renderCartProductsSection()
                : renderEmptySection()}
        </div>
    );
};

Cart.propTypes = {
    onClose: PropTypes.func,
};
export default Cart;
