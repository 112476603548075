import React, { useEffect, useRef, useState } from "react";
import Button from "components/button";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import Input from "components/input";
import { personIcon, plus, search } from "resources/images/images";
import Dropdown from "components/dropdown";
import CheckBox from "components/checkbox";
import TextArea from "components/textarea";
import styles from "./style.module.css";
import { getAllSellerDetailsApi } from "networking/apis/seller/getallsellerdetails";
import Popup from "components/popup/popup";
import { registerProductApi } from "networking/apis/products/registerproduct";
import { getStoreMetaDataApi } from "networking/apis/storemeta/storemeta";
import { useAuthContext } from "hooks/useAuthContext";
import { structuringData } from "helpers";
import { editProductApi } from "networking/apis/products/editproduct";
import SelectInput from "components/selectInput/selectInput";
import { IoCaretDownSharp } from "react-icons/io5";
import { useToast } from "providers/ToastProvider";
import { getSellerDetailsApi } from "networking/apis/seller/getsellerdetails";
import { useSpinner } from "providers/SpinnerProvider";
const AddProduct = () => {
    /*checkBoxOptions*/
    const checkBoxOptions = [
        "Consignment deal 50%",
        "Custom consignment deal",
        "Resale",
        "Retail",
    ];
    /*context*/

    const { storeData } = useAuthContext();
    const { showToast } = useToast();
    const { setIsLoading } = useSpinner();

    /*routing*/
    const navigate = useNavigate();
    const location = useLocation();
    const editProductData = location?.state?.data;
    const type = location?.state?.type;
    console.log(editProductData);

    /*error message displaying*/
    const [error, setError] = useState("");
    /*handle seller data states*/

    const [sellerData, setSellerData] = useState(null);
    const [sellersData, setSellersData] = useState([]);
    const [searchedSellerData, setSearchedSellerData] = useState([]);
    const [sellerId, setSellerId] = useState("");
    const [searchResultsExist, setSearchResultsExist] = useState(false);
    const [selectedSellerId, setSelectedSellerId] = useState("");

    /*handle brands data*/

    const [brand, setBrand] = useState(
        editProductData
            ? { value: editProductData?.brand, label: editProductData?.brand }
            : { value: "", label: "" }
    );
    const [brandsData, setBrandsData] = useState();

    /*handle productCategory data */

    const [productCategory, setProductCategory] = useState(
        editProductData
            ? {
                  value: editProductData?.category,
                  label: editProductData?.category,
              }
            : {
                  value: "",
                  label: "",
              }
    );
    const [productCategorysData, setProductCategorysData] = useState();

    /*handle consignmentPercentage data*/

    const [consignmentPercentage, setConsignmentPercentage] = useState(
        editProductData?.consignment
            ? checkBoxOptions.includes(editProductData?.consignment)
                ? editProductData?.consignment
                : "Custom consignment deal"
            : ""
    );

    const [customConsignmentPercentage, setCustomConsignmentPercentage] =
        useState(
            editProductData
                ? checkBoxOptions.includes(editProductData?.consignment)
                    ? ""
                    : editProductData?.consignment
                : ""
        );

    /*handle price data*/

    const [price, setPrice] = useState(
        editProductData ? editProductData?.price : ""
    );
    /*handle models data*/

    const [model, setModel] = useState(
        editProductData
            ? { value: editProductData?.model, label: editProductData?.model }
            : { value: "", label: "" }
    );
    const [modelsData, setModelsData] = useState();

    /*handle colors data*/

    const [color, setColor] = useState(
        editProductData
            ? { value: editProductData?.colour, label: editProductData?.colour }
            : { value: "", label: "" }
    );
    const [colorsData, setColorsData] = useState();

    /*handle materials data*/

    const [material, setMaterial] = useState(
        editProductData
            ? {
                  value: editProductData?.material,
                  label: editProductData?.material,
              }
            : { value: "", label: "" }
    );
    const [materialsData, setMaterialsData] = useState();

    /*handle sizes data*/

    const [size, setSize] = useState(
        editProductData
            ? {
                  value: editProductData?.size,
                  label: editProductData?.size,
              }
            : { value: "", label: "" }
    );
    const [sizes, setSizesData] = useState();

    /*handle product uid*/

    const [productUid, setProductUid] = useState(editProductData?.product_uid);

    /*handle comment*/
    const [comment, setComment] = useState(
        editProductData ? editProductData?.comment : ""
    );
    /*custom input and their state values*/

    const [visibility, setVisibility] = useState(false);
    const [customInput, setCustomInput] = useState("");
    const [customInputValue, setCustomInputValue] = useState("");
    const [registeredProductDeatails, setRegisteredProductDetails] =
        useState("");

    /*store meta data*/

    const [storeMetaData, setStoreMetaData] = useState(null);

    /*handle product states*/

    const [productStatus, setProductStatus] = useState(
        editProductData ? editProductData?.availability_status : "InStore"
    );

    const [actionLoading, setActionLoading] = useState(false);

    /*handle price input and its format*/

    const formatPriceWithCommas = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleChange = (e) => {
        const inputPrice = e.target.value.replace(/\D/g, "");
        setPrice(inputPrice);
    };

    const handleBlur = () => {
        setPrice(formatPriceWithCommas(price));
    };

    // call the apis onload the page

    useEffect(() => {
        handleGetAllSellerDetails();
        handleGetStoreMetaData();
    }, []);
    useEffect(() => {
        if (editProductData?.brand === "") {
            handleGetSellerDeatailsAndProducts(editProductData?.seller_id?._id);
        }
    }, [editProductData]);

    /*product status options*/
    const options = [
        { value: "InStore", label: "InStore" },
        { value: "Sold", label: "Sold" },
        { value: "Paid Out", label: "Paid Out" },
        { value: "Packed", label: "Packed" },
    ];

    const tapOnSave = () => {
        setActionLoading(true);
        setTimeout(() => {
            setActionLoading(false);
            navigate(-1);
        }, 3000);
    };

    /*form validations*/

    const validations = () => {
        if (editProductData && type === "edit") {
            handleEditProduct();
        } else {
            if (sellerData === null) {
                setError("Please provide seller");
            }
            // else if (brand.value === "") {
            //     setError("Please provide brand");
            // }
            //  else if (productCategory.value === "") {
            //     setError("Please provide product category");
            // }
            //  else if (model.value === "") {
            //     setError("Please provide model");
            // }
            //  else if (color.value === "") {
            //     setError("Please provide color");
            // }
            //  else if (material.value === "") {
            //     setError("Please provide material");
            // }
            // else if (size.value === "") {
            //     setError("Please provide size");
            // }
            // else if (consignmentPercentage === "") {
            //     setError("Please provide consignment");
            // }
            // else if (price === "") {
            //     setError("Please provide price");
            // }
            // else if (productStatus === "") {
            //     setError("Please provide status");
            // }
            else {
                handleRegisterProduct();
            }
        }
    };

    /*get all seller details*/

    const handleGetAllSellerDetails = async () => {
        try {
            const getAllSellerDetailsResponse = await getAllSellerDetailsApi();

            setSellersData(getAllSellerDetailsResponse.data.data);
        } catch (error) {
            console.log("error in fetching seller details", error);
        }
    };
    const handleGetSellerDeatailsAndProducts = async (id) => {
        try {
            const response = await getSellerDetailsApi(id);
            if ((response.data.type = "success")) {
                // setProductUid(
                //     `${response.data.data[0].seller_uid}.${
                //         response.data.data[0].products.length + 1
                //     }`
                // );
                setSellerData(response.data.data[0]);
            }
        } catch (error) {
            console.log("error", error);
        }
    };
    /*register a product*/

    const handleRegisterProduct = async () => {
        setIsLoading(true);
        try {
            let productRegisterData = {
                seller_id: sellerData._id,
                brand: brand.value,
                category: productCategory.value,
                consignment: customConsignmentPercentage
                    ? customConsignmentPercentage
                    : consignmentPercentage,
                model: model.value,
                colour: color.value,
                material: material.value,
                size: size.value,
                availability_status: productStatus,
                price: price,
                comment: comment,
            };

            const registerProductResponse = await registerProductApi(
                productRegisterData
            );
            if (registerProductResponse.data.type === "success") {
                console.log(registerProductResponse);
                setIsLoading(false);
                setRegisteredProductDetails(registerProductResponse.data.data);
                showToast.success("Product added successfully");
                // navigate(-1);
                setBrand("");
                setProductCategory("");
                setConsignmentPercentage("");
                setModel("");
                setColor("");
                setMaterial("");
                setSize("");
                setProductStatus("InStore");
                setPrice("");
                setComment("");
            } else {
                setIsLoading(false);
                showToast.error("Product details not filled");
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };

    /*edit product*/

    const handleEditProduct = async () => {
        setIsLoading(true);
        try {
            const editedProductData = {
                _id: editProductData?._id,
                brand: brand.value,
                category: productCategory.value,
                model: model.value,
                colour: color.value,
                material: material.value,
                size: size.value,
                consignment:
                    consignmentPercentage === "Custom consignment deal"
                        ? editProductData?.consignment ===
                          customConsignmentPercentage
                            ? editProductData?.consignment
                            : customConsignmentPercentage
                        : consignmentPercentage,
                price: price,
                availability_status: productStatus,
                comment: comment,
            };

            const editedProductResponse = await editProductApi(
                editedProductData
            );
            if (editedProductResponse.data.type === "success") {
                console.log("editedProductResponse", editedProductResponse);
                setIsLoading(false);
                showToast.success(editedProductResponse.data.message);
                navigate(-1);
            } else {
                console.log("editedProductResponse", editedProductResponse);
                setIsLoading(false);
                showToast.error(editedProductResponse.data.message);
                console.log("error in editing");
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };

    /*get store meta data*/

    const handleGetStoreMetaData = async () => {
        try {
            const getStoreMetaDataResponse = await getStoreMetaDataApi(
                storeData.store._id
            );

            setBrandsData(
                structuringData(getStoreMetaDataResponse.data.storemeta.brands)
            );

            setProductCategorysData(
                structuringData(
                    getStoreMetaDataResponse.data.storemeta.category
                )
            );

            setModelsData(
                structuringData(getStoreMetaDataResponse.data.storemeta.model)
            );
            setColorsData(
                structuringData(getStoreMetaDataResponse.data.storemeta.color)
            );
            setMaterialsData(
                structuringData(
                    getStoreMetaDataResponse.data.storemeta.material
                )
            );
            setSizesData(
                structuringData(getStoreMetaDataResponse.data.storemeta.size)
            );

            setStoreMetaData(getStoreMetaDataResponse.data.storemeta);
        } catch (error) {
            console.log("error", error);
        }
    };

    /*serach the list of sellers*/

    const handleSeach = (e) => {
        if (sellersData) {
            const searchQuery = e.target.value.toLowerCase();
            const filteredSellers = sellersData.filter((item) =>
                item.seller_name.toLowerCase().includes(searchQuery)
            );
            setSellerData();
            if (searchQuery !== "" && filteredSellers.length > 0) {
                setSearchedSellerData(filteredSellers);
                setSearchResultsExist(true);
            } else {
                setSearchedSellerData([]);
                setSearchResultsExist(false);
            }
        }
    };

    const renderLeftSection = () => {
        return (
            <p className={styles.titleTextStyle}>
                {editProductData && type === "edit"
                    ? "Edit Product"
                    : "Add new product"}
            </p>
        );
    };

    // react select dropdown indicator

    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <SearchIcon />
                </components.DropdownIndicator>
            )
        );
    };

    // react select dropdown indicator icon

    const SearchIcon = () => (
        <div className={styles.searchIconViewStyle}>
            <IoCaretDownSharp className={styles.dropdownIcon} />
        </div>
    );

    const renderSellerSctions = () => {
        return (
            <div className={styles.sellerSearchViewStyle}>
                <p className={styles.lableTextStyle}>Select seller *</p>
                <div className={styles.searchSellerSubViewStyle}>
                    <div className={styles.searchSellerLeftViewStyle}>
                        <SelectInput
                            name="sellersInput"
                            customInputStyles={styles.sellerInputStyles}
                            type={"text"}
                            placeholder="select"
                            image={search}
                            value={
                                editProductData
                                    ? editProductData?.seller_id?.seller_name
                                    : sellerData?.seller_name
                            }
                            onChange={(e) => {
                                handleSeach(e);
                                setError("");
                            }}
                            options={searchedSellerData}
                            searchResultsExist={searchResultsExist}
                            onChangeOptions={(selectedOption) => {
                                setSellerData(selectedOption);
                                setSearchResultsExist(false);
                                setSelectedSellerId(selectedOption.seller_uid);
                                handleGetSellerDeatailsAndProducts(
                                    selectedOption._id
                                );
                            }}
                            disabled={!sellersData ? true : false}
                        />
                        {!sellersData ? (
                            <p className={styles.sellerMsgInfo}>
                                please add seller to proceed
                            </p>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className={styles.searchSellerRightViewStyle}>
                        {sellerId === "" ? (
                            <Button
                                title={
                                    selectedSellerId
                                        ? selectedSellerId
                                        : editProductData
                                        ? editProductData?.seller_id?.seller_uid
                                        : "Add seller"
                                }
                                icon={
                                    selectedSellerId
                                        ? ""
                                        : editProductData
                                        ? ""
                                        : plus
                                }
                                onClick={() =>
                                    selectedSellerId
                                        ? ""
                                        : editProductData
                                        ? ""
                                        : navigate("/addSeller")
                                }
                                btnStyle={styles.btnViewStyle}
                            />
                        ) : (
                            <Button
                                title={sellerId}
                                icon={personIcon}
                                onClick={() => console.log("das")}
                                btnStyle={styles.btnViewStyle}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    /*onclick the button(+) display popup*/

    const renderPopUpSection = () => {
        return (
            <Popup
                show={visibility}
                title={popUpTitle()}
                value={customInputValue}
                onChange={(e) => {
                    setCustomInputValue(e.target.value);
                }}
                name={popInputName()}
                placeholder={popPlaceHolderName()}
                lableText={popUpLabelText()}
                closeHandler={() => {
                    setVisibility(false);
                    setCustomInput("");
                }}
                addProductValue={(e) => {
                    handleCustomeInput(e);
                    setTimeout(() => {
                        setVisibility(false);
                    }, 200);
                }}
            />
        );
    };

    /*handle  popup title*/

    const popUpTitle = () => {
        if (customInput === "brand") {
            return "Brand";
        } else if (customInput === "productCategory") {
            return "Product Category";
        } else if (customInput === "color") {
            return "Color";
        } else if (customInput === "model") {
            return "Model";
        } else if (customInput === "material") {
            return "Material";
        } else if (customInput === "size") {
            return "Size";
        } else if (customInput === "consignment") {
            return "consignment (%)";
        }
    };

    /*handle popup input name*/

    const popInputName = () => {
        if (customInput === "brand") {
            return "brand";
        } else if (customInput === "productCategory") {
            return "productCategory";
        } else if (customInput === "color") {
            return "color";
        } else if (customInput === "model") {
            return "model";
        } else if (customInput === "material") {
            return "Material";
        } else if (customInput === "size") {
            return "Material";
        } else if (customInput === "consignment") {
            return "ConsignmentPercentage";
        }
    };

    /*handle popup placeholder name*/

    const popPlaceHolderName = () => {
        if (customInput === "brand") {
            return "brand";
        } else if (customInput === "productCategory") {
            return "product Category";
        } else if (customInput === "color") {
            return "color";
        } else if (customInput === "model") {
            return "model";
        } else if (customInput === "material") {
            return "material";
        } else if (customInput === "size") {
            return "size";
        } else if (customInput === "consignment") {
            return "consignmentPercentage";
        }
    };

    /*handle popuplabel text*/

    const popUpLabelText = () => {
        if (customInput === "brand") {
            return "Enter Brand Name";
        } else if (customInput === "productCategory") {
            return "Enter Product Category";
        } else if (customInput === "color") {
            return "Enter Color";
        } else if (customInput === "model") {
            return "Enter Model";
        } else if (customInput === "material") {
            return "Enter Material";
        } else if (customInput === "size") {
            return "Enter Size";
        } else if (customInput === "consignment") {
            return "Enter Consignment Percentage";
        }
    };

    /*handle popup custom input*/

    const handleCustomeInput = () => {
        if (customInput === "brand") {
            setBrandsData((prev) => [
                ...prev,
                { label: customInputValue, value: customInputValue },
            ]);
            setCustomInputValue("");
            setBrand({ label: customInputValue, value: customInputValue });
        } else if (customInput === "productCategory") {
            setProductCategorysData((prev) => [
                ...prev,
                { label: customInputValue, value: customInputValue },
            ]);
            setCustomInputValue("");
            setProductCategory({
                label: customInputValue,
                value: customInputValue,
            });
        } else if (customInput === "color") {
            setColorsData((prev) => [
                ...prev,
                { label: customInputValue, value: customInputValue },
            ]);
            setCustomInputValue("");
            setColor({
                label: customInputValue,
                value: customInputValue,
            });
        } else if (customInput === "model") {
            setModelsData((prev) => [
                ...prev,
                { label: customInputValue, value: customInputValue },
            ]);
            setCustomInputValue("");
            setModel({
                label: customInputValue,
                value: customInputValue,
            });
        } else if (customInput === "material") {
            setMaterialsData((prev) => [
                ...prev,
                { label: customInputValue, value: customInputValue },
            ]);
            setCustomInputValue("");
            setMaterial({
                label: customInputValue,
                value: customInputValue,
            });
        } else if (customInput === "size") {
            setSizesData((prev) => [
                ...prev,
                { label: customInputValue, value: customInputValue },
            ]);
            setCustomInputValue("");
            setSize({
                label: customInputValue,
                value: customInputValue,
            });
        } else if (customInput === "consignment") {
            const parsedInput = parseFloat(customInputValue);
            if (isNaN(parsedInput) || parsedInput < 0 || parsedInput > 100) {
                /* Invalid input, show an error message or take appropriate action*/
                showToast.error(
                    "Please enter a valid percentage between 0 and 100."
                );
            } else {
                setCustomConsignmentPercentage(parsedInput + "%");
                setConsignmentPercentage("Custom consignment deal");
            }
        }
    };

    const renderBrandSection = () => {
        return (
            <div className={styles.sectionViewStyle}>
                <Dropdown
                    lableText="Brand"
                    options={brandsData}
                    value={brand}
                    onChange={(text) => {
                        setBrand(text);
                        setError("");
                    }}
                    containerViewStyle={styles.eachSectionViewStyle}
                    openPopUp={() => {
                        setCustomInput("brand");
                        setVisibility(true);
                    }}
                />
                <div className={styles.eachSectionViewStyle}>
                    <p className={styles.lableTextStyle}>Product number</p>
                    <Input
                        placeholder="Product uid..."
                        value={productUid}
                        disabled={true}
                        onChange={(e) => setProductUid(e.target.value)}
                        customInputStyle={styles.productUidStyle}
                    />
                </div>
            </div>
        );
    };

    const renderCategorySection = () => {
        return (
            <div className={styles.sectionViewStyle}>
                <Dropdown
                    lableText="Product category"
                    options={productCategorysData}
                    value={productCategory}
                    onChange={(text) => {
                        setProductCategory(text);
                        setError("");
                    }}
                    containerViewStyle={styles.eachSectionViewStyle}
                    openPopUp={() => {
                        setCustomInput("productCategory");
                        setVisibility(true);
                    }}
                />
                <div className={styles.checkboxSectionViewStyle}>
                    <div>
                        <p className={styles.lableTextStyle}>Sales agreement</p>
                        <div className={styles.checkboxSubSectionViewStyle}>
                            {checkBoxOptions.map((item, index) => (
                                <div key={index}>
                                    <CheckBox
                                        lableText={
                                            item === "Custom consignment deal"
                                                ? `${item}`
                                                : item
                                        }
                                        checked={
                                            consignmentPercentage === item
                                                ? true
                                                : consignmentPercentage !==
                                                      item &&
                                                  consignmentPercentage === item
                                        }
                                        onClick={() => {
                                            if (index === 1) {
                                                setCustomInput("consignment");
                                                setVisibility(true);
                                            } else {
                                                setVisibility(false);
                                                setCustomConsignmentPercentage(
                                                    ""
                                                );
                                                setConsignmentPercentage(item);
                                            }
                                        }}
                                        customPercenatage={
                                            item ===
                                                "Custom consignment deal" &&
                                            customConsignmentPercentage
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.statusSectionStyle}>
                        <p className={styles.lableTextStyle}>Status</p>
                        {/* <Select
                            defaultValue={
                                productStatus === true
                                    ? options.find(
                                          (option) => option.value === "InStore"
                                      )
                                    : options.find(
                                          (option) => option.value === "Sold"
                                      )
                            }
                            onChange={(e) => {
                                handleProductStatusSelectedOption(e);
                                setError("");
                            }}
                            options={options}
                            isSearchable={true}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator,
                            }}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    background:
                                        productStatus === "InStore"
                                            ? "linear-gradient(0deg, rgba(71, 199, 26, 0.40) 0%, rgba(71, 199, 26, 0.40) 100%), rgba(0, 0, 0, 0.00)"
                                            : "linear-gradient( 0deg, rgba(255, 0, 0, 0.2) 0%, rgba(255, 0, 0, 0.2) 100% ), rgba(0, 0, 0, 0)",
                                    borderRadius: "16px",
                                    minHeight: "24px",
                                    width: "106px",
                                    borderColor: state.isFocused
                                        ? "black"
                                        : "gray",

                                    fontFamily: "Inter-Regular",
                                    fontSize: "14px",
                                }),
                                option: (baseStyles) => ({
                                    ...baseStyles,
                                    fontFamily: "Inter-Regular",
                                    fontSize: "14px",
                                }),
                            }}
                        /> */}
                        <Select
                            value={options.find(
                                (option) => option.value === productStatus
                            )}
                            onChange={(selectedOption) =>
                                setProductStatus(selectedOption.value)
                            }
                            options={options}
                            isSearchable={true}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator,
                            }}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    background:
                                        productStatus === "InStore"
                                            ? "linear-gradient(0deg, rgba(71, 199, 26, 0.40) 0%, rgba(71, 199, 26, 0.40) 100%), rgba(0, 0, 0, 0.00)"
                                            : productStatus === "Sold"
                                            ? "linear-gradient(0deg, rgba(255, 0, 0, 0.2) 0%, rgba(255, 0, 0, 0.2) 100%), rgba(0, 0, 0, 0)"
                                            : productStatus === "Paid Out"
                                            ? "#FFED8E"
                                            : productStatus === "Packed"
                                            ? "#AEB6D6"
                                            : "",
                                    borderRadius: "16px",
                                    minHeight: "24px",
                                    width: "106px",
                                    borderColor: state.isFocused
                                        ? "black"
                                        : "gray",
                                    fontFamily: "Inter-Regular",
                                    fontSize: "14px",
                                }),
                                option: (baseStyles) => ({
                                    ...baseStyles,
                                    fontFamily: "Inter-Regular",
                                    fontSize: "14px",
                                }),
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderModelAndPriceSection = () => {
        return (
            <div className={styles.sectionViewStyle}>
                <Dropdown
                    lableText="Model"
                    options={modelsData}
                    // value={model}
                    value={model}
                    onChange={(text) => {
                        setModel(text);

                        setError("");
                    }}
                    openPopUp={() => {
                        setCustomInput("model");
                        setVisibility(true);
                    }}
                    containerViewStyle={styles.eachSectionViewStyle}
                />
                <div className={styles.eachSectionViewStyle}>
                    <Input
                        placeholder="Enter price..."
                        lableText="Price"
                        // value={price}
                        value={formatPriceWithCommas(price)}
                        onChange={handleChange}
                        // onChange={(e) => {
                        //     setPrice(e.target.value);
                        //     setError("");
                        // }}
                        onBlur={handleBlur}
                        lableTextStyle={styles.lableTextStyle}
                        customInputStyle={styles.customInputViewStyle}
                    />
                </div>
            </div>
        );
    };

    const renderOtherSections = () => {
        return (
            <div className={styles.sectionViewStyle}>
                <div className={styles.otherLeftSectionStyle}>
                    <Dropdown
                        lableText="Colour"
                        options={colorsData}
                        value={color}
                        onChange={(text) => {
                            setColor(text);

                            setError("");
                        }}
                        openPopUp={() => {
                            setCustomInput("color");
                            setVisibility(true);
                        }}
                    />
                    <Dropdown
                        lableText="Material"
                        options={materialsData}
                        value={material}
                        onChange={(text) => {
                            setMaterial(text);

                            setError("");
                        }}
                        openPopUp={() => {
                            setCustomInput("material");
                            setVisibility(true);
                        }}
                    />
                    <Dropdown
                        lableText="Size"
                        options={sizes}
                        value={size}
                        onChange={(text) => {
                            setSize(text);

                            setError("");
                        }}
                        openPopUp={() => {
                            setCustomInput("size");
                            setVisibility(true);
                        }}
                    />
                </div>
                <div className={styles.eachSectionViewStyle}>
                    <TextArea
                        placeholder="Enter comment..."
                        lableText="Comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        lableTextStyle={styles.lableTextStyle}
                        customInputStyle={styles.commentInputViewStyle}
                    />
                </div>
            </div>
        );
    };

    const renderBtnSection = () => {
        return (
            <div className={styles.saveBtnSectionViewStyles}>
                {error && <p className={styles.errorMsgStyle}>{error}</p>}
                {/* {(editProductData?.availability_status || !editProductData) && ( */}
                <Button
                    title={
                        actionLoading
                            ? "Loading ...."
                            : editProductData && type === "edit"
                            ? "Edit Product"
                            : "Save Product"
                    }
                    onClick={validations}
                    btnStyle={styles.saveBtnStyle}
                />
                {/* )} */}
                <p className={styles.createdTextStyle}>
                    {editProductData?.availability_status &&
                        "Created: 02.03.2023"}
                </p>
            </div>
        );
    };
    const renderFormSection = () => {
        return (
            <div className={styles.formViewStyle}>
                {renderSellerSctions()}
                {renderBrandSection()}
                {renderCategorySection()}
                {renderModelAndPriceSection()}
                {renderOtherSections()}
                {renderBtnSection()}
            </div>
        );
    };

    return (
        <div className={styles.containerStyle}>
            <div className={styles.leftSectionStyle}>
                {renderLeftSection()}
                {renderFormSection()}
                {renderPopUpSection()}
            </div>
            <div className={styles.rightSectionStyle}>
                <Button
                    title="Back"
                    onClick={() => navigate(-1)}
                    btnStyle={styles.customBtnStyle}
                />
            </div>
        </div>
    );
};
export default AddProduct;
