import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../components/input";
import Button from "../../components/button";
import { useAuthContext } from "hooks/useAuthContext";
import styles from "./styles.module.css";
import { useToast } from "providers/ToastProvider";
import { forGotPassWordApi } from "networking/apis/store/forgotpassword";
import { useSpinner } from "providers/SpinnerProvider";
const ForGotPassWord = () => {
    /*context*/
    const { storeLogin, setErrorMessage, errorMessage } = useAuthContext();
    const { showToast } = useToast();
    const { setIsLoading } = useSpinner();

    /*routing*/
    const navigate = useNavigate();

    /*states*/
    const [actionLoading, setActionLoading] = useState(false);

    /*Forgot password validation schema*/

    const ForGotPassWordSchema = Yup.object().shape({
        email: Yup.string()
            .email("Enter a valid email address")
            .required("Email is required"),
    });

    /*handleForGotPassWord login*/

    const handleForGotPassWord = async (values, { resetForm }) => {
        setIsLoading(true);
        try {
            const forGotPassWordResponse = await forGotPassWordApi({
                email: values.email,
            });

            if (forGotPassWordResponse.data.type === "success") {
                console.log("values", forGotPassWordResponse);
                setIsLoading(false);
                showToast.success(forGotPassWordResponse.data.message);
                resetForm();
            } else {
                setIsLoading(false);
                showToast.error(forGotPassWordResponse.data.messsage);
            }
            // navigate("/resetpassword");
            // setActionLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };

    /*initialization of formik values*/

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: ForGotPassWordSchema,
        onSubmit: handleForGotPassWord,
    });

    const renderInputSection = () => {
        return (
            <div className={styles.inputSectionStyle}>
                <p className={styles.titleTextStyle}>Forgot Password</p>
                <div className={styles.inputSubSectionStyle}>
                    <div className={styles.emailInputStyle}>
                        <Input
                            name="email"
                            lableText={"Email"}
                            placeholder={"Enter your email"}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onFocus={() => {
                                formik.setFieldError("email", "");
                                setErrorMessage("");
                            }}
                            onBlur={formik.handleBlur}
                            hasError={
                                formik.touched.email && formik.errors.email
                                    ? formik.errors.email
                                    : ""
                            }
                            customInputStyle={styles.emailInputStyles}
                        />
                    </div>
                    {errorMessage && (
                        <p className={styles.erroMessageStyles}>
                            {errorMessage}
                        </p>
                    )}
                </div>
            </div>
        );
    };

    const renderButtonSection = () => {
        return (
            <div className={styles.btnSectionStyle}>
                <Button
                    type="submit"
                    title={actionLoading ? "Loading..." : "Submit"}
                    btnStyle={styles.btnViewStyle}
                />
            </div>
        );
    };

    return (
        <div className={styles.containerStyle}>
            <form
                onSubmit={formik.handleSubmit}
                className={styles.subContainerStyle}
            >
                {renderInputSection()}
                {renderButtonSection()}
            </form>
        </div>
    );
};
export default ForGotPassWord;
