import React from "react";
import styles from "./style.module.css";
import PropTypes from "prop-types";

const Input = (props) => {
    const {
        type,
        name,
        value,
        onChange = () => {},
        placeholder,
        hasError,
        lableText,
        lableTextStyle,
        customInputStyle,
        customInputViewStyle,
        customRightIconViewStyle,
        rightIcon,
        leftIcon,
        customLeftIconViewStyle,
        customErrorTextStyle,
        disabled,
        ...rest
    } = props;

    return (
        <div className={styles.inputContainerStyle}>
            {lableText && (
                <p
                    className={[styles.lableTextStyle, lableTextStyle].join(
                        " "
                    )}
                >
                    {lableText}
                </p>
            )}
            <div
                className={[
                    styles.inputSubContainerStyle,
                    customInputViewStyle,
                ].join(" ")}
            >
                {leftIcon && (
                    <div
                        className={[
                            styles.leftIconViewStyle,
                            customLeftIconViewStyle,
                        ].join(" ")}
                    >
                        <img src={leftIcon} className={styles.leftIconStyle} />
                    </div>
                )}
                <input
                    type={type || "text"}
                    name={name}
                    disabled={disabled}
                    className={[styles.inputStyle, customInputStyle].join(" ")}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder || ""}
                    {...rest}
                />
                {rightIcon && (
                    <div
                        className={[
                            styles.rightIconViewStyle,
                            customRightIconViewStyle,
                        ].join(" ")}
                    >
                        <img
                            src={rightIcon}
                            className={styles.rightIconStyle}
                        />
                    </div>
                )}
            </div>
            {hasError && (
                <p
                    className={[
                        styles.errorTextStyle,
                        customErrorTextStyle,
                    ].join(" ")}
                >
                    {hasError}
                </p>
            )}
        </div>
    );
};

Input.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    hasError: PropTypes.string,
    lableText: PropTypes.string,
    lableTextStyle: PropTypes.string,
    customErrorTextStyle: PropTypes.string,
};
export default Input;
